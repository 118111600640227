import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FloorplanNav from "./Floorplan/FloorplanNav";
import CancelNav from "./CancelNav";
import FloorplanModal from "./Floorplan/FloorplanModal";
import { useDesignNavContext } from "../../store/DesignNavContext";
import {AuthUserContext} from "../../session/AuthUserContext";
import DesignContext from "../../store/DesignContext";
import projects from "../../images/projects.svg";
import play from "../../images/play.svg";
import broom from "../../images/broom.svg";
import heatmap from "../../images/heatmap.svg";
import print from "../../images/print.svg";
import floorplan from "../../images/floorplan.svg";
import freezeZoomIcon from "../../images/no-magnify.svg";
import zoomIcon from "../../images/magnify.svg";
import zoomToFitIcon from "../../images/magnify-expand.svg";
import detectWallsIcon from "../../images/wall.svg";
import map from "../../images/map.svg";
import threeD from "../../images/3d.svg";
import { getWalls, updateFloorplanWalls } from "../../utils/apis";
import "../../styles/topNav.css";
import "../../styles/design/designTopNav.css";

function DesignTopNav(props) {

    const [state, dispatch] = useDesignNavContext();

    const { showFloorplans, showDesignNav, showFloorplanNav, showCancelNav, wallsActive, freezeZoom } = state;

    const [designState, setDesignState] = useContext(DesignContext);

    const {fileLink, projName, projId, planId, planSelected } = designState;
    const [title, setTitle] = useState("");
 

    const {uid}= useContext(AuthUserContext);

    useEffect( async () => {
      if (projName && planSelected) {
        setTitle(`${projName}:  ${planSelected}` )
      } else {
        setTitle(projName)
      }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projId, planSelected]);

    const handleRun = (e) => {
      e.preventDefault();
      dispatch({type: "displayRunOptions"})
    }
    const handleUndoPropagation = (e) => {
      e.preventDefault();
      dispatch({type: "hidePropagation"})
   
    }
    const handleLastPropagation = (e) => {
      e.preventDefault();
      dispatch({type: "displayPropagation"})
    }
    const handle3D = (e) => {
      e.preventDefault();

    }
    const handleOpenMap = (e) => {
      e.preventDefault();

    }

    const handleDetectWalls = async (e) => {
      e.preventDefault();
      if (fileLink) {
        const fileData = {
            UID: uid,
            link: fileLink
        }
        // wall format from api "x1, y1, x2, y2, x3, y3, x4, y4, ..."
        let wallsString = (await getWalls(fileData)).data.wallsArray;

        // wall array transformed from api, [x1, y1, x2, y2, x3, y3, x4, y4]
        const walls1DArray = wallsString.split(",")

        // wall array transformed for ease of displaying on ui, 
        // [ [x1, y1, x2, y2], [x3, y3, x4, y4] ... ]
        const walls2DArray = [];
        const walls2DArrayCopy = [];
        for (let i=0; i<walls1DArray.length; i+=4) {
            walls2DArray.push([parseFloat(walls1DArray[i]), parseFloat(walls1DArray[i+1]), parseFloat(walls1DArray[i+2]), parseFloat(walls1DArray[i+3])])
            walls2DArrayCopy.push([parseFloat(walls1DArray[i]), parseFloat(walls1DArray[i+1]), parseFloat(walls1DArray[i+2]), parseFloat(walls1DArray[i+3])])
        }

        // interface array - will be stringified for storage in firebase
        // and modified per user modification
        // [ [x1, y1, x2, y2, x3, y3, wall type], [ [x1, y1, x2, y2, x3, y3, wall type], ...]
        const interfaceWallsArray = walls2DArrayCopy.map( (w) => {
          w.push(4); //default wall type
          return w;
        });

        setDesignState({...designState, walls1DArray, walls2DArray, interfaceWallsArray })

        // format to store in firebase
        //"x1, y1, x2, y2, x3, y3; wall type: x1, y1, x2, y2, x3, y3; wall type: ..."
        let interfaceWallsString = "";
        interfaceWallsArray.forEach( (w) => {
          for (let i=0; i< w.length-2; i++) {
            interfaceWallsString += w[i] + ', ';
          }
          interfaceWallsString += (w[w.length-2]) + "; ";
          interfaceWallsString += w[w.length-1] + ": "
        })

        // store in firebase
        updateFloorplanWalls(uid, projId, planId, interfaceWallsString);
      }
      // set to display walls
      dispatch({type: "displayWalls"})
      
    }

    const handlePrint = (e) => {
      e.preventDefault();
      console.log("printing...")

    }

    const handleFreezeZoom = (e) => {
      e.preventDefault();
      freezeZoom ? dispatch({type: "unFreezeZoom"}) : dispatch({type: "freezeZoom"}) ;
    }

    const handleZoomFit = async (e) => {
      e.preventDefault();
      dispatch({type: "fitToZoom"})
    }

    const handleNavState = (e, action) => {
      e.preventDefault();
      dispatch({type: action})
    }

    return (<>
      {showFloorplanNav && <FloorplanNav handleNavState={handleNavState}/> }
      {showCancelNav && <CancelNav handleNavState={handleNavState}/> }
      { showFloorplans && <FloorplanModal handleNavState={handleNavState}/>}
      { showDesignNav && (
        <header id="design-top-nav" className="top-nav">
            
            <span id="design-title">{title}</span>
            <div className="nav-left-icon-div">
              <a className="nav-icon-container" onClick={(e) => handleRun(e)} href='' type="button">
                  <img className="nav-icon-link" src={play} alt="play-icon"/>
                  <span id="design-play-text" className="nav-icon-text">Run</span>
              </a>
              <a className="nav-icon-container" onClick={(e) => handleUndoPropagation(e)} href='' type="button">
                  <img className="nav-icon-link" src={broom} alt="broom-icon"/>
                  <span id="design-undo-text" className="nav-icon-text">Undo</span>
              </a>
              <a className="nav-icon-container" onClick={(e) => handleLastPropagation(e)} href='' type="button">
                  <img className="nav-icon-link" src={heatmap} alt="gradient-icon"/>
                  <span id="design-last-text" className="nav-icon-text">Last</span>
              </a>

              

              {/* <div id="left-propagation-divider" className="vertical-line"></div> */}

              {wallsActive && (
              <>
                <div id="left-propagation-divider" className="vertical-line"></div>

                <a className="nav-icon-container" onClick={(e) => handleDetectWalls(e)} href='' type="button">
                    <img className="nav-icon-link" src={detectWallsIcon} alt="map-icon"/>
                    <span id="design-detect-text" className="nav-icon-text">Detect</span>
                </a>
              </>
              )}


              {/* <a className="nav-icon-container" onClick={(e) => handle3D(e)} href='' type="button">
                  <img className="nav-icon-link" src={threeD} alt="3d-icon"/>
                  <span id="design-3d-text" className="nav-icon-text">3D Plan</span>
              </a>
              <a className="nav-icon-container" onClick={(e) => handleOpenMap(e)} href='' type="button">
                  <img className="nav-icon-link" src={map} alt="map-icon"/>
                  <span id="design-map-text" className="nav-icon-text">Map</span>
              </a> */}
            </div>
            <div className="nav-right-icon-div">
              {/* {freezeZoom && <a onClick={(e) => handleFreezeZoom(e)} className="nav-icon-container" onClick={(e) => handleOpenMap(e)} href='' type="button">
                  <img className="nav-icon-link" src={freezeZoomIcon} alt="map-icon"/>
                  <span id="design-map-text" className="nav-icon-text">Freeze</span>
              </a>} */}

              <a id="zoom-fit-wrapper" className="nav-icon-container" onClick={(e) => handleZoomFit(e)} href='' type="button">
                  <img className="nav-icon-link" src={zoomToFitIcon} alt="zoom-expand-icon"/>
                  <span id="design-zoom-fit-text" className="nav-icon-text">Fit</span>
              </a>
              {!freezeZoom && (
              <>
              <a className="nav-icon-container" onClick={(e) => handleFreezeZoom(e)} href='' type="button">
                  <img className="nav-icon-link" src={freezeZoomIcon} alt="print-icon"/>
                  <span id="design-freeze-text" className="nav-icon-text">Freeze</span>
              </a>
              <div id="right-freeze-divider" className="vertical-line"></div>
              </>
              )}

              {freezeZoom && (
              <>
              <a className="nav-icon-container" onClick={(e) => handleFreezeZoom(e)} href='' type="button">
                  <img className="nav-icon-link" src={zoomIcon} alt="print-icon"/>
                  <span id="design-unfreeze-text" className="nav-icon-text">Unfreeze</span>
              </a>
              <div id="right-unfreeze-divider" className="vertical-line"></div>
              </>
              )}

              {/* {!freezeZoom && <a onClick={(e) => handleFreezeZoom(e)} className="nav-icon-container" onClick={(e) => handleOpenMap(e)} href='' type="button">
                  <img className="nav-icon-link" src={freezeZoomIcon} alt="map-icon"/>
                  <span id="design-map-text" className="nav-icon-text">Freeze</span>
              </a>}

              <a className="nav-icon-container" onClick={(e) => handleFreezeZoom(e)} href='' type="button">
                  <img className="nav-icon-link" src={print} alt="print-icon"/>
                  <span id="design-print-text" className="nav-icon-text">Print zoom</span>
              </a> */}

              


              {/* <a className="nav-icon-container" onClick={(e) => handlePrint(e)} href='' type="button">
                  <img className="nav-icon-link" src={print} alt="print-icon"/>
                  <span id="design-print-text" className="nav-icon-text">Print</span>
              </a>

              <div id="right-print-divider" className="vertical-line"></div>
               */}
               
              <a className="nav-icon-container" onClick={(e) => handleNavState(e, "noPlan")} href='' type="button">
                  <img className="nav-icon-link" src={floorplan} alt="floorplan-icon"/>
                  <span id="design-floorplans-text" className="nav-icon-text">Floorplans</span>
              </a>
              <Link className="nav-icon-container" to="/project">
                <img className="nav-icon-link" src={projects} alt="projects-icon"/> 
                <span id="design-projects-text" className="nav-icon-text">Projects</span>
              </Link>
            </div> 
        </header>
      )}
    </>);
}

export default DesignTopNav;