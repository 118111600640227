import React, { useState } from "react";
import { AuthUserContext} from "./AuthUserContext";
import { auth } from '../firebase/auth';

function WithAuthentication(Component) {
    const WithAuthComponent = () => {     

        const [user, setUser] = useState('');

        auth.onAuthStateChanged(authUser => {
            authUser
              ? setUser(authUser)
              : setUser("")
        });
        
        return (
            <AuthUserContext.Provider value={user}>
                <Component />
            </AuthUserContext.Provider>
        );
     }
    return WithAuthComponent;
}

export default WithAuthentication;