import React from "react";
import server from "../images/server-off.svg";
import "../styles/message.css";



function Message(props) {

    const handleCancel = (e)=>{
        e.preventDefault();
        props.setMessage("");
    }

    return (
        <div style={{...props.style}}id="message-wrapper">
            <p style={{color: props.message.color}}id="message">{props.message.text}</p>
            {!props.message.hideCancel && <div onClick={handleCancel} id="message-footer">
                <a id="cancel-message" href="">
                    Cancel
                </a>
            </div>}
        </div>

    );
}

export default Message;