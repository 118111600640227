import React, {useState, useEffect} from "react";
import "../styles/spinner.css";

function Spinner(props) {

   const [location] = useState(props.location);

   useEffect(()=>{
   }, [])

    return (
    <>
        {props.style && <div style={props.style} id={location + '-spinner-container'}className="spinner-container">
            <div id={location + '-spinner'} className="spinner"></div>
        </div>
        }
        {!props.style && <div id={location + '-spinner-container'} className="spinner-container">
            <div id={location + '-spinner'}className="spinner"></div>
        </div>
        }
    </>
    );
}

export default Spinner;
