import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { signInWithEmailAndPassword } from "../../firebase/auth";
import {AuthUserContext} from "../../session/AuthUserContext";
import AuthNav from "./AuthNav";
import "../../styles/auth/auth.css";

function Login() {

  const [formState, setFormState] = useState({
    email: "",
    password: ""
  });
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });
  const [passwordShow, setPasswordShow] = useState("show");
  const [passwordType, setPasswordType] = useState("password");

  const { email, password } = formState;
  const { messageText, messageColor } = message;

  const history = useHistory();

  const user= useContext(AuthUserContext);

  useEffect(() => {
    if (user) history.replace("/project");
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
    setMessage({ messageText: "", messageColor: "" });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email === "" || password === "") {
      setMessage({
        messageText: "Please complete all fields.",
        messageColor: "red",
      });
      return;
    }

    try {
      await signInWithEmailAndPassword(email, password);
    } catch(err) {
      setMessage({
        messageText: "Your email or password is incorrect. Please try again",
        messageColor: "red",
      });
    }
  }

  const toggleShow = () => {
    if (passwordShow === "show") {
      setPasswordShow("hide");
      setPasswordType("text");
    } else {
      setPasswordShow("show");
      setPasswordType("password");
    }
  }

  return (
    <div className="body-container">
      <AuthNav />
      <div className="auth-card">
        <form  onSubmit={handleSubmit}>
          <div className="auth-form-header">
            <span>Sign in</span>
          </div>
            <input
              type="text"
              className="auth-input"
              name="email"
              onChange={handleChange}
              placeholder="Email *"
            />
            <div className="input-container">
              <input
                type={passwordType}
                className="auth-input"
                name="password"
                onChange={handleChange}
                placeholder="Password *"
              />
              <span className="show-password blue-link" onClick={toggleShow}>{passwordShow}</span>
            </div>
            <button className="auth-submit">
              Sign in
            </button>
          </form>
          <div>
            <Link to="/password" className="blue-link">Forgot Password?</Link>
          </div>
          {messageText && (
            <p className={messageColor}>{messageText}</p>
          )}
        </div>
    </div>
  );
}

export default Login;
