import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withAuthorization from '../../session/withAuthorization';
import ProjectNav from "./ProjectNav";
import Message from "../Message";
import trashcan from "../../images/trashcan.svg";
import darkTrashcan from "../../images/trashcan-dark.svg";
import pencil from "../../images/edit.svg";
import darkPencil from "../../images/edit-dark.svg";
import { getAllProjects, deleteProjectById } from "../../utils/apis";
import "../../styles/project/project.css";
import Spinner from "../Spinner";

function Project(props) {

    const [projects, setProjects] = useState([]);
    const [hoveredTrashcan, setHoveredTrashcan] = useState("");
    const [hoveredPencil, setHoveredPencil] = useState("");
    const [showSpinner, setShowSpinner] = useState(true);

    const [message, setMessage] = useState(null);

    const deleteProject = async (e, projId) => { 
        e.preventDefault();
        try {
            await deleteProjectById(props.uid, projId);
            const projFiltered = projects.filter((proj)=>proj.id !== projId);
            setProjects(projFiltered);
            if (projFiltered.length === 0) {
                setMessage({text: "You do not have any projects yet. Please create a project.", color: "#031e3a", hideCancel: true});
            }
        } catch(err) {
            if (err) {
                setMessage({text: "There was an error deleting your project. Please try again later or contact customer support.", color: "#ff0000"});
            }
        }  
    }

    const toggleTrashcan = (e, index) => { 
        e.preventDefault();
        hoveredTrashcan ? setHoveredTrashcan("") : setHoveredTrashcan(index);
    }

    const togglePencil = (e, index) => { 
        e.preventDefault();
        hoveredPencil ? setHoveredPencil("") : setHoveredPencil(index);
    }

    useEffect(() => {
        getProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProjects = async () => {
        let projectData;
        try {
            projectData = await getAllProjects(props.uid);
        } catch(err) {
            if (err) {
                setMessage({text:"There was an error retrieving your projects. Please try again later or contact customer support.", color: "#ff0000"});
                setShowSpinner(false);   
                return;
            }
        }  
        setShowSpinner(false);   
        if (projectData){
            setProjects(projectData);
        } else {
            setMessage({text: "You do not have any projects yet. Please create a project.", color: "#031e3a", hideCancel: true});
        }
        
       
    }

    return (
        <div className="body-container">
            <ProjectNav />
            {showSpinner && <Spinner location="projects"/>}
            {(projects.length > 0) && projects.map( (p) => {
                return (
                    <div key={p.id} className="project-card">
                        <div className="project-card-icon-div">
                            <Link className="project-setup" to={`/project/setup/${p.id}`} onMouseOver={(e) => togglePencil(e, p.id)} onMouseOut={(e) => togglePencil(e)} href='' type="button">
                                <img src={hoveredPencil === p.id ? darkPencil : pencil} alt="pencil-icon"/>
                            </Link>
                            <a className="project-delete" onClick={(e) => deleteProject(e, p.id)} onMouseOver={(e) => toggleTrashcan(e, p.id)} onMouseOut={(e) => toggleTrashcan(e)} href='' type="button">
                                <img src={hoveredTrashcan === p.id ? darkTrashcan : trashcan} alt="trashcan-icon"/>
                            </a>
                        </div>
                        <Link className="project-card-summary blue-link" to={"/design/"+ p.id}>
                            <p className="project-name">{p.name}</p>
                            <p className="project-date">{p.date}</p>
                        </Link>
                    </div>
                )
            })}  
            {message && (
            <>
            
            <Message message={message} setMessage={setMessage}/>
            {/* {messageStyle && (
                <>
                <div id="message-background"></div>
                <Message style={messageStyle} message={message} setMessage={setMessage}/>
                </>
            )} */}
            </>
            )}
        </div>
    );
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(Project);