import React from "react";
import { Link } from "react-router-dom";
import { signOut } from "../../firebase/auth";
import logo from "../../images/logo.png";
import logout from "../../images/signout.svg";
import add from "../../images/add.svg";
import account from "../../images/account.svg";
import "../../styles/topNav.css";
import "../../styles/project/projectNav.css";

function ProjectNav(props) {
    return (
        <header id="project-nav" className="top-nav">
            <a id="project-nav-logo-link" onClick={signOut} href='' type="button">
                <img id="project-nav-logo" src={logo} alt="logo"/>
            </a>
            <div className="nav-right-icon-div">
                <Link className="nav-icon-container" to="/project/setup">
                    <img className="nav-icon-link" src={add} alt="add-icon"/>
                    <span id="project-nav-add-text" className="nav-icon-text">New</span>
                </Link>
                <Link className="nav-icon-container" to="/account">
                    <img className="nav-icon-link" src={account} alt="account-icon"/>
                    <span id="project-nav-account-text" className="nav-icon-text">Account</span>
                </Link>
                <div className="nav-icon-container">
                    <a className="nav-icon-link" onClick={signOut} href='' type="button">
                        <img src={logout} alt="signout-icon"/>
                    </a>
                    <span id="project-nav-signout-text" className="nav-icon-text">Sign Off</span>
                </div>
            </div>
        </header>
    );
}

export default ProjectNav;