import React, {useEffect, useContext, useState} from "react";
import { useHistory, useParams } from "react-router";
import {AuthUserContext} from "../../../session/AuthUserContext";
import DesignContext from "../../../store/DesignContext";
import { useDesignNavContext } from "../../../store/DesignNavContext";
import Spinner from "../../Spinner";
import { getFloorplansByProjId } from "../../../utils/apis";
// import { getAllProjects, deleteProjectById } from "../../../utils/apis";
import "../../../styles/design/floorplan/floorplanList.css";
import trashcan from "../../../images/trashcan.svg";
import darkTrashcan from "../../../images/trashcan-dark.svg";

import { deleteFloorplanById, deleteFileByFileId,  } from "../../../utils/apis";

function FloorplanList(props) {
    const [floorplans, setFloorplans] = useState([]);
    const [hoveredTrashcan, setHoveredTrashcan] = useState("");
    const [showSpinner, setShowSpinner] = useState(true);

    const {projId} = useParams();
    const {uid}= useContext(AuthUserContext);

    const [state, dispatch] = useDesignNavContext();
    const [designState, setDesignState] = useContext(DesignContext);

    useEffect(()=>{
    })

    useEffect( async () => {
        console.log("getting Floorplans from Firebase");
        const floorplans = await getFloorplansByProjId(uid, projId);
        setShowSpinner(false);
        setDesignState({...designState, projId})
        if (floorplans.length > 0) {
          setFloorplans(floorplans)
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const deleteFloorplan = async (e, planId) => { 
        e.preventDefault();
        try {
            const planToDelete = floorplans.filter((plan)=>plan.id == planId);
            console.log("plan to delete", planToDelete[0]);
            console.log("props uid", props.uid);
            await deleteFileByFileId(planToDelete[0].fileName, null, uid);
            await deleteFloorplanById(uid, projId, planId);
            const plansFiltered = floorplans.filter((plan)=>plan.id !== planId);
            setFloorplans(plansFiltered);
        } catch(err) {
    
            // if (err) {
            //     setError("There was an error deleting your project. Please try again later or contact customer support.");
            // }
        }  
    }
    
    const formatWalls = (interfaceWallsString) => {
        const walls2D =[];
        const interfaceArr = [];

        // convert walls from firebase format to display format if not done already
        let interfaceWallsSplit = interfaceWallsString.split(":");
        // each item looks like this: "x1, y1, x2, y2; wall type"
        for (let i =0; i<interfaceWallsSplit.length-1; i++) {
            const singleWallSplit = interfaceWallsSplit[i].split(";");
            const walls = singleWallSplit[0];
            const wallType = parseInt(singleWallSplit[1]);

            const wallCoordSplit = walls.split(",");
            // convert each coord to a number
            const singleWallArr = wallCoordSplit.map((coord)=>{
                return parseFloat(coord);
            })

            // 2D array for displaying, [ [x1, y1, x2, y2], [x3, y3, x4, y4] ... ]
            walls2D.push(singleWallArr);

            //interface array for user modifications,
            // [ [x1, y1, x2, y2, x3, y3, wall type], [ [x1, y1, x2, y2, x3, y3, wall type], ...]
            const singleWallArrWithType = [...singleWallArr, wallType]
            interfaceArr.push(singleWallArrWithType);
        }
        return [walls2D, interfaceArr]
    }

    const openFloorplan = (e, id) => { 
        e.preventDefault();
        // globally store all info about selected plan for design page to use
        const { planName, 
                fileLink,
                fileName,
                transmitters,
                geoReference, // 2 icons on google maps
                lastLegend, 
                lastPrediction, 
                predictionArea, // selection to run propagaton on
                scale, // default is 16
                bandwidth, // 10 or 20
                imageReference, // scale coordinates
                walls 
        } = (floorplans.filter((plan)=>plan.id === id))[0];

        let updatedBinaryTransmittersCopy=[];

        for (let i =0; i<transmitters.length;i++) {
            updatedBinaryTransmittersCopy.push([...transmitters[i]])
        }

        let updatedBinaryTransmitters = updatedBinaryTransmittersCopy.map((transmitter) => {
            // We will no longer substract 20 when loading form FB
            // transmitter[2] = transmitter[2] -20;
            // transmitter[3] = transmitter[3] -20;
            return transmitter;
        })

        let updatedBinaryScaleCopy=[];

        for (let i =0; i<imageReference.length;i++) {
            updatedBinaryScaleCopy.push([...imageReference[i]])
        }
    
        let updatedBinaryScaleCoord = updatedBinaryScaleCopy.map((scale) => {
            return [scale[0]-15, scale[1]-15]
        })

        let walls2DArray = [];
        let interfaceWallsArray = [];

        if (walls) {
            const formattedWalls = formatWalls(walls);
            walls2DArray = formattedWalls[0];
            interfaceWallsArray = formattedWalls[1];
        }

        // convert transmitters to array
        // last session wall values needed if user runs propagation without 
        // toggling walls, which populates walls2DArray and interfaceWallsArray
        setDesignState({...designState, 
                        planId: id,
                        planSelected: planName,
                        selectedFile: "",
                        fileLink,
                        fileName,
                        geoReference: geoReference || [], 
                        lastLegend, 
                        bandwidth: bandwidth || 10,
                        binaryScaleCoord: updatedBinaryScaleCoord || [],
                        lastPrediction,
                        predictionArea: predictionArea || [], 
                        pixelsPerMeter: scale || 16, 
                        scaleCoord: [],
                        binaryTransmitters: updatedBinaryTransmitters || [],
                        transmitters: [],
                        walls1DArray: [],
                        walls2DArray: [],
                        interfaceWallsArray: [],
                        lastSessionWalls2DArray: walls2DArray,
                        lastSessionInterfaceWallsArray: interfaceWallsArray,
                        interfaceWallsString: walls || ""
                        });
        // open design page with image
        dispatch({type: "AP"});
    }

    const toggleTrashcan = (e, id) => { 
        e.preventDefault();
        hoveredTrashcan ? setHoveredTrashcan("") : setHoveredTrashcan(id);
    }

    return (
        <div>
            {showSpinner && <Spinner location="floorplan"/>}
            {floorplans.length > 0 && floorplans.map((plan)=>{
                return (
                    <div key={plan.id} className="floorplan-list-card">
                        <div className="floorplan-icon-div">
                            <a className="floorplan-delete" onClick={(e) => deleteFloorplan(e, plan.id)} onMouseOver={(e) => toggleTrashcan(e, plan.id)} onMouseOut={(e) => toggleTrashcan(e)} href='' type="button">
                                <img src={hoveredTrashcan === plan.id ? darkTrashcan : trashcan} alt="trashcan-icon"/>
                            </a>
                        </div>
                        <a className="floorplan-list-summary blue-link" onClick={(e) => openFloorplan(e, plan.id)} href="" type="button">
                            <p className="floorplan-list-name">{plan.planName}</p>
                        </a>
                    </div>
                )
            })}
        </div>
    );
}

export default FloorplanList;
