import axios from "axios";

export const postFileData = async (data) => {
  try {
    const fileData = await axios
    .post("/api/files/upload", data);
    if (fileData) {
      return fileData.data;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const deleteFileByFileId = async (fileName, projId, uid) => {
  console.log("Delete AWS File Params: ", fileName, projId, uid);
  try {
    const deletedFile = await axios({
      url: "/api/files",
      method: "DELETE",
      data: {
        fileName, 
        projId, 
        uid
      },
    });
    if (deletedFile && deletedFile.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

