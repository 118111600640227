import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DesignContext from "../../store/DesignContext";
import projects from "../../images/projects.svg";
import "../../styles/topNav.css";
import "../../styles/projectSetup/projectSetupNav.css";
import save from "../../images/save.svg";

function ProjectSetupNav(props) {

  const [designState, setDesignState] = useContext(DesignContext);

  const handleSaveForm =  (e)=> {
    e.preventDefault();
    setDesignState({
      ...designState,
      saveForm: true
    })
  }

  return (
      <header id="project-setup-nav" className="top-nav">
          <span id="project-setup-title">{props.title}</span>
          <div className="nav-right-icon-div">
            <a className="nav-icon-container" onClick={(e) => handleSaveForm(e)} href='' type="button">
                <img className="nav-icon-link" src={save} alt="save-icon"/>
                <span id="floorplan-save-text" className="nav-icon-text">Save</span>
            </a>

            <div id="right-projects-divider" className="vertical-line"></div>

            <Link className="nav-icon-container" to="/project">
              <img className="nav-icon-link" src={projects} alt="projects-icon"/> 
              <span id="project-setup-projects-text" className="nav-icon-text">Projects</span>
            </Link>

          </div>
      </header>
  );
}

export default ProjectSetupNav;