const ZOOM_FACTOR = 0.04;
const ZOOM_FACTOR_IN = 1 + ZOOM_FACTOR;
const ZOOM_FACTOR_OUT = 1 - ZOOM_FACTOR;

//We will define the reducer and actions in separate files. This way only the implementations of the event handlers remain in our custom hook.

//One advantage of using the action creators startPan, pan and zoom is that we're very explicit about the responsibilities of each event handler:


export const types = {
  PAN: 'PAN',
  PAN_START: 'PAN_START',
  ZOOM: 'ZOOM',
  GET_UNSCALED_XY: 'GET_UNSCALED_XY',
  UNZOOM_AND_PAN: 'UNZOOM_AND_PAN'
};

export const startPan = (event) => ({
  type: types.PAN_START,
  clientX: event.clientX,
  clientY: event.clientY,
});

export const pan = (event) => ({
  type: types.PAN,
  clientX: event.clientX,
  clientY: event.clientY,
});

export const zoom = (event, containerRect) => ({
  type: types.ZOOM,
  deltaY: event.deltaY,
  zoomFactor: event.deltaY < 0 ? ZOOM_FACTOR_IN : ZOOM_FACTOR_OUT,
  clientX: event.clientX,
  clientY: event.clientY,
  containerRect: containerRect,
});

export const reverseScaleXY = (event, containerRect) => ({
  type: types.GET_UNSCALED_XY,
  zoomFactor: - event.deltaY < 0 ? ZOOM_FACTOR_IN : ZOOM_FACTOR_OUT,
  clientX: event.clientX,
  clientY: event.clientY,
  containerRect: containerRect,
});

export const startUnzoomAndPan = () => ({
  type: types.UNZOOM_AND_PAN
})
