import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail } from "../../firebase/auth";
import AuthNav from "./AuthNav";
import "../../styles/auth/auth.css";

function PasswordResetForm() {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState({ messageText: "", messageColor: "" });

  const { messageText, messageColor } = message;
  const history = useHistory();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    sendPasswordResetEmail(email);
    setMessage({ messageText: "We have sent a password reset link to your email.", messageColor: "green" });
  };

  return (
    <div className="body-container">
      <AuthNav />
      <div className="auth-card">
        <form onSubmit={handleSubmit}>
          <div className="auth-form-header">
            <span>Reset Password</span>
          </div>
          <input
            className="auth-input"
            type="email"
            name="email"
            placeholder="Email Address *"
            onChange={handleChange}
          />
          <button className="auth-submit">Reset password</button>
        </form>
        <div className="auth-form-footer">
          <Link className="grey-message-text cancel-form" to="/login">
            Cancel
          </Link>
        </div>
        {messageText && (
          <p className={messageColor}>{messageText}</p>
        )}
      </div>
    </div>
  );
}

export default PasswordResetForm;
