import { useRef, useReducer, useCallback } from 'react'
import {reducer, initialState } from './zoomReducer';
import { pan, startPan, zoom, reverseScaleXY, startUnzoomAndPan } from './zoomActionCreators';
export const usePanAndZoom = () => {
  //useReducer is a good match for this use-case since we have one state which is accessed by multiple event handlers. 
  const [state, dispatch] = useReducer(reducer, initialState);
  const containerRef = useRef(null);

  // the naming of the event handlers for mouse up and move are more explicit. onMouseMoveInWindow makes it clearer that the handler is listening to move events inside the complete window, not only the image container. 
  //The reason is that we want the panning to start only inside the image container, but moving and ending the pan should also be possible outside.
  const onMouseMoveInWindow = useCallback((event) => {
    event.preventDefault();
    dispatch(pan(event));
  },[]);

  const removePanListeners = useCallback(() => {
    window.removeEventListener('mouseup', removePanListeners);
    window.removeEventListener('mousemove', onMouseMoveInWindow);
  }, []);


  const onMouseDownPan = (event) => {
    // remove before adding listeners to make sure not adding duplicates
    window.removeEventListener('mouseup', removePanListeners);
    window.removeEventListener('mousemove', onMouseMoveInWindow);
    window.addEventListener('mouseup', removePanListeners);
    window.addEventListener('mousemove', onMouseMoveInWindow);
    dispatch(startPan(event));
  }

  //Since react renders the page new on every state change, it assigns this function new within each render. To use removeEventListener, the callbacks, aka this function, must pass an equality check.
  //useCallback memoizes the function so the equality check will pass
  const onWheel = useCallback((event) => {
    event.preventDefault();
    if (event.deltaY !== 0 && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      dispatch(zoom(event, containerRect));
    }
  },[])

  const ZOOM_FACTOR = 0.1;
  const ZOOM_FACTOR_IN = 1 + ZOOM_FACTOR;
  const ZOOM_FACTOR_OUT = 1 - ZOOM_FACTOR;

  const getUnscaledXY =(event, imgHeight, imgWidth, halfIconSize) => {
      const containerRect = containerRef.current.getBoundingClientRect();
      return {
        clientXReversed: ((event.clientX - containerRect.left)/state.scale - ((state.translateX + imgWidth / 2) / state.scale - (imgWidth / 2))),//20 is half mini div
        clientYReversed: ((event.clientY - containerRect.top)/state.scale - ((state.translateY + imgHeight / 2) / state.scale - (imgHeight / 2))) //20 is half mini div
      }
  }

  const unzoomAndPan = () => {
    dispatch(startUnzoomAndPan())
  }

  return {
    ...state,
    containerRef,
    onMouseDownPan,
    removePanListeners,
    onWheel,
    getUnscaledXY,
    unzoomAndPan
  }
}