import { firebase } from './firebase';
const auth = firebase.auth();

const signInWithEmailAndPassword = async (email, password) => 
    auth.signInWithEmailAndPassword(email, password);
  
const createUserWithEmailAndPassword = async (name, email, password) => 
  auth.createUserWithEmailAndPassword(email, password);

const sendPasswordResetEmail = async (email) => 
  auth.sendPasswordResetEmail(email);

const signOut = async() => 
  await auth.signOut();

export {
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
};