import axios from "axios";

export const getAccountById = async (user) => {
    try {
        const {data} = await axios
            .get("/api/accounts", {
                headers: {
                    'source': user
                }
        });
        if (data) {
            return data;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};

export const postAccountData = async (accountToSave) => {
    try {
        const accountData = await axios.post("/api/accounts", accountToSave);
        if (accountData) {
            return accountData;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};