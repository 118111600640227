import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getProjectById, postFileData, postProjectData, deleteFileByFileId } from "../../utils/apis";
import withAuthorization from '../../session/withAuthorization';
import DesignContext from "../../store/DesignContext";
import ProjectSetupNav from "./ProjectSetupNav";
import "../../styles/projectSetup/projectSetup.css";

function ProjectSetup(props) {

    const [formState, setFormState] = useState({
      id: "",
      name: "", 
      description: "", 
      address: "", 
      city: "", 
      state: "",
      unitImperial: "1",
      selectedFile: "",
      fileMessage: "no file selected", 
      fileTypeImage: false, 
      formElement: "",
      fileMessageColor: ""
    });

    const [savedFileData, setSavedFileData] = useState({pictureLink: "", pictureName: ""});
    const [newFileToSave, setNewFileToSave] = useState({fileLink: "", uniqueFileName: ""});

    const [fileUploadText, setFileUploadText] = useState("Add file");
    const [message, setMessage] = useState({ messageText: "", messageColor: "" });

    const { name, description, address, city, state, unitImperial, selectedFile, fileMessage, fileTypeImage, formElement, fileMessageColor } = formState;

    const { pictureLink, pictureName }= savedFileData;
    const { fileLink, uniqueFileName } = newFileToSave
    const { messageText, messageColor } = message;

    const [designState, setDesignState] = useContext(DesignContext);

    const {saveForm} = designState;

    const {id} = useParams();
    const history = useHistory();

    useEffect(()=> {
      if (saveForm) {
        handleSubmit();
        setDesignState({
          ...designState,
          saveForm: false
        })
      }
      
    },[saveForm])

    useEffect( async () => {
      // if proj id in params
      if (id) {
        const {name, description, address, city, state, unitImperial, pictureLink, pictureName } = await getProjectById(props.uid, id);
          if (pictureLink && pictureName) {
            setSavedFileData({pictureLink, pictureName })
            setFileUploadText("Change file");
            setFormState({
              ...formState, id, name, description, address, city, state, unitImperial,
              fileMessage: "image below"
            });
          }  else {
            setFormState({...formState, id, name, description, address, city, state, unitImperial})
          }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDrop = (event) =>{
      // Prevent default behavior (Prevent file from being opened)
      event.preventDefault();
      if (event.dataTransfer.files[0]) {
        let file = event.dataTransfer.files[0];
        if (file) {
          saveFileState(file);
        }
      }
    }

    const handleDragOver = (event) => {
      // Prevent default behavior (Prevent file from being opened)
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();

    }

    //sets the state of selectedFile by accessing the FileListobject provided by the DOM containing a list of File objects representing the files selected by the user
    const handleSelectedFile = (event) => {
      event.preventDefault();
      let file = event.target.files[0];
      if (file) {
        saveFileState(file);
      }
    };

    const saveFileState = (file) => {
      let fileImage = false;
      if ((file.type).includes("image")) {
        fileImage = true;
        setFormState({
          ...formState, 
          selectedFile: file,
          fileMessage: file.name,
          fileMessageColor: "",
          fileTypeImage: fileImage, 
          formElement: formElement
        });
        setFileUploadText("Change file");
        setMessage({ messageText: "", messageColor: "" });
      } else {
        setFormState({
          ...formState, 
          fileMessage: "The file uploaded must be an image.",
          fileMessageColor: "red"
        });
      }
    }

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormState({ ...formState, [name]: value });
      setMessage({ messageText: "", messageColor: "" });
    };

    const handleSubmit = async (event) => {
      if (event) {
        event.preventDefault();
      }
      let fileSaved = "";
      if (!name) {
        setMessage({
          messageText: "Please enter a project name.",
          messageColor: "red",
        });
        return;
      } 
      try {
        let linkToSave = pictureLink;
        let nameToSave = pictureName;
        if (selectedFile) {
          fileSaved = await submitFileData();
          if (pictureName) {
            await deleteFile();
          }
          linkToSave = fileSaved["link"];
          nameToSave = fileSaved.name;
        }

        const projToSave = {
          uid: props.uid,
          id,
          name,
          description,
          address,
          city,
          state,
          unitImperial,
          pictureLink: linkToSave,
          pictureName: nameToSave
        }
        await postProjectData(projToSave);
        history.replace("/project")

      } catch (err) {
        setMessage({
          messageText: `There was an error saving this form. Please try again or contact customer service.`,
          messageColor: "red",
        });
      }
    };

    const deleteFile = async (e) => {
      if (e) e.preventDefault();
      const deletedFile = await deleteFileByFileId(pictureName, id, props.uid);
      if (deletedFile) {
        setSavedFileData({pictureLink: "", pictureName: ""});
      } 
    }

    const submitFileData = async () => {
      // creates a new instance of the FormData object
      const data = new FormData();
  
      // appends a set of key/value pairs to the above FormData object
      // visible at fileData.get("file")
      data.append("file", selectedFile);
  
      const fileData = await postFileData(data);
  
      return fileData;
    };

    return (
        <div className="body-container">
            <ProjectSetupNav title="Project Details" />
            <form id="project-form" onSubmit={handleSubmit}>
              <div>
                <p className="detail">Project Name <span className="red">*</span>: </p>
                <input
                  className="project-form-input"
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <p className="detail">Description: </p>
                <textarea
                  type="text"
                  name="description"
                  value={description}
                  onChange={handleChange}
                />
              </div>
              <div>
                <p className="detail">Address: </p>
                <input
                  className="project-form-input"
                  type="text"
                  name="address"
                  value={address}
                  onChange={handleChange}
                />
              </div>
              <div>
                <p className="detail">City: </p>
                <input
                  className="project-form-input"
                  type="text"
                  name="city"
                  value={city}
                  onChange={handleChange}
                />
              </div>
              <div>
                <p className="detail">State: </p>
                <input
                  className="project-form-input"
                  type="text"
                  name="state"
                  value={state}
                  onChange={handleChange}
                />
              </div>
              <div>
                <p className="detail">Measurements: </p>
                <select
                  name="unitImperial"
                  value={unitImperial}
                  onChange={handleChange}
                >
                  <option value="1">Imperial</option>
                  <option value="0">Metric</option>
                </select>
              </div> 
              <div>
                <p className="detail">Attachment: </p>
                <div id="file-drop-zone" onDrop={(e) => handleDrop(e)} onDragOver={(e)=> handleDragOver(e)}>
                  <div id="select-file-wrapper">
                    {/* File type - returns a list of selected File objects as a FileList  */}
                    <input
                      type="file"
                      multiple={false}
                      id="select-file-input"
                      onChange={handleSelectedFile}
                    />   
                    <a className="blue-link">
                      {fileUploadText}
                    </a>
                    <span id="drop-file"> or drop file here</span>
                    <p id="file-message" className= {fileMessageColor}>{fileMessage}</p>
                  </div>
                </div>

                {/* <a className="blue-link" href="#project-img">Preview Image</a> */}
              </div>

              <button id="project-submit">Save</button>
            </form>
            <div id="project-form-footer">
              <Link className="cancel-form blue-link" to="/project">
                Cancel
              </Link>
            </div>
            {messageText && (
              <p className={messageColor + " form-err-message"}>{messageText}</p>
            )}
          <div id="image-view">
            {selectedFile && <img id='project-img' src={URL.createObjectURL(selectedFile)}/>}
            { (pictureLink && !selectedFile) && <>
              <a id="delete-project-img" className="blue-link" onClick={(e) => deleteFile(e)} href="#">Delete Image</a> <div id='project-img' ><img id='project-img' src={pictureLink}/></div></>
            }
          </div>
        </div>
    );
}
//https://visual-cbrs.s3.amazonaws.com/Screen%20Shot%202021-11-01%20at%204.00.11%20PM.png_52fdcd85983bf71fce5ac3a8ca4c829f51d0a425

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(ProjectSetup);

