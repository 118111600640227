import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { getAccountById, postAccountData } from "../../utils/apis";
import DesignContext from "../../store/DesignContext";
import withAuthorization from '../../session/withAuthorization';
import ProjectSetupNav from "../ProjectSetup/ProjectSetupNav"
import "../../styles/account/account.css";

import "../../styles/projectSetup/projectSetup.css";

function Account(props) {

    const [formState, setFormState] = useState({
        userTitle: "",
        userName: "",
        userOrganization: "",
        userAddress: ""
      });
      const [message, setMessage] = useState({ messageText: "", messageColor: "" });
    
      const { userTitle, userName, userOrganization, userAddress } = formState;
      const { messageText, messageColor } = message;

      const [designState, setDesignState] = useContext(DesignContext);

      const {saveForm} = designState;

      const history = useHistory();

      useEffect( async() => {
        const formData = await getAccountById(props.uid);
        setFormState({...formState, ...formData})
      }, []);

      useEffect(()=> {
        if (saveForm) {
          handleSubmit();
          setDesignState({
            ...designState,
            saveForm: false
          })
        }
      },[saveForm])

      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState({ ...formState, [name]: value });
        setMessage({ messageText: "", messageColor: "" });
      };
    
      const handleSubmit = async (event) => {
        if (event) {
          event.preventDefault();
        }
        try {
          const accountToSave = {
            ...formState,
            uid: props.uid
          }
          await postAccountData(accountToSave);
          history.replace("/project")
        } catch (err) {
          setMessage({
            messageText: `There was an error saving this form. Please try again or contact customer service.`,
            messageColor: "red",
          });
        }
      };
      return (
        <div className="body-container">
            <ProjectSetupNav title="Account Details" />
            <form id="project-form" onSubmit={handleSubmit}>
              <p>
                <p className="detail">Title: </p>
                <input
                  className="project-form-input"
                  type="text"
                  name="userTitle"
                  value={userTitle}
                  onChange={handleChange}
                />
              </p>
              <p>
                <p className="detail">Name: </p>
                <input
                  className="project-form-input"
                  type="text"
                  name="userName"
                  value={userName}
                  onChange={handleChange}
                />
              </p>
              <p>
                <p className="detail">Organization: </p>
                <input
                  className="project-form-input"
                  type="text"
                  name="userOrganization"
                  value={userOrganization}
                  onChange={handleChange}
                />
              </p>
              <p>
                <p className="detail">Address: </p>
                <input
                  className="project-form-input"
                  type="text"
                  name="userAddress"
                  value={userAddress}
                  onChange={handleChange}
                />
              </p>
              <button id="project-submit">Save</button>
            </form>
            <div id="project-form-footer">
              <Link className="cancel-form blue-link" to="/project">
                Cancel
              </Link>
            </div>
            {messageText && (
              <p className={messageColor + " form-err-message"}>{messageText}</p>
            )}
        </div>
    );
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(Account);

