import React, { createContext, useContext } from "react";
import { useDesignNavReducer } from './navReducer'

const DesignNavContext = createContext();

const { Provider } = DesignNavContext;

const designNavInitialState = {
    signalState: "disabled", 
    wallsState: "disabled", 
    geoScaleState: "disabled",
    notesState: "disabled", 
    showTransmitter: false,
    showScaleCrosshairs: false,
    showLeftLogo: true,
    showFloorplans: true,
    showDesignNav: false, 
    showFloorplanNav: true,
    showProjSpinner: true, 
    showFloorplansSpinner: true, 
    transmitterActive: false,
    wallsActive: false,
    scaleActive: false,
    showWalls: false,
    showNotes: false,
    showScale: false,
    showRunOptions: false,
    showAPOptions: false,
    showCancelNav: false,
    showPropagation: false,
    showManualScale: false, 
    freezeZoom: false, 
    showDesignSpinner: false,
    unzoom: false
}

const DesignNavProvider = ({ value = [], ...props }) => {
  const [state, dispatch] = useDesignNavReducer(designNavInitialState);

  return <Provider value={[state, dispatch]} {...props} />;
};

const useDesignNavContext = () => {
  return useContext(DesignNavContext);
};

export { DesignNavProvider, useDesignNavContext };
