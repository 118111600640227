import { useReducer } from "react";

export const navReducer = (state, action) => {
    switch (action.type) {
      case "noPlan":
        return {
          ...state,                          
          signalState: "disabled", 
          wallsState: "disabled", 
          geoScaleState: "disabled",
          notesState: "disabled", 
          showTransmitter: false,
          showScale: false,
          showLeftLogo: true,
          showFloorplans: true,
          showDesignNav: false, 
          showFloorplanNav: true, 
          showWalls: false,
          showNotes: false,
          showScale: false,
          showRunOptions: false,
          showAPOptions: false,
          showCancelNav: false,
          showPropagation: false, 
          showManualScale: false,
        };
      case "AP":
        return {
          ...state,
          showTransmitter: true,
          showLeftLogo: false,
          showFloorplans: false,
          showDesignNav: true,
          showFloorplanNav: false, 
          signalState: "", 
          wallsState: "",
          notesState: "",
          geoScaleState: "",
          showWalls: false,
          showNotes: false,
          showScale: false,
          showRunOptions: false,
          showAPOptions: false,
          showCancelNav: false,
          scaleActive: false,
          transmitterActive: false,
          wallsActive: false,
          showManualScale: false
        };
      case "displayWalls":
        return {
          ...state,
          showWalls: true,
          showNotes: false,
          showScale: false,
          wallsState: "",
          geoScaleState: "disabled",
          notesState: "disabled"
        }
      case "displayNotes":
        return {
          ...state,
          showNotes: true,
          showWalls: false,
          showScale: false,
          showTransmitter: false,
          wallsState: "disabled",
          geoScaleState: "disabled",
          notesState: ""
        }
      case "displayScale":
        return {
          ...state,
          showScale: true,
          showTransmitter: false,
          showNotes: false, 
          showWalls: false,
          wallsState: "disabled",
          geoScaleState: "",
          notesState: "disabled"
        }
      case "hideFloorplans":
        return {
          ...state,
          showFloorplans: false,
        }
      case "displayPropagation":
        return {
          ...state,
          showPropagation: true,
        }
        case "hidePropagation":
          return {
            ...state,
            showPropagation: false,
          }
      case "displayRunOptions":
        return {
          ...state,
          showRunOptions: true,
          showAPOptions: false,
          showCancelNav: true,
          showDesignNav: false,
        }
      case "hideRunOptions":
        return {
          ...state,
          showRunOptions: false,
          showAPOptions: false,
          showCancelNav: false,
          showDesignNav: true,
        }
      case "displayAPOptions":
        return {
          ...state,
          showRunOptions: false,
          showCancelNav: true,
          showDesignNav: false,
          showAPOptions: true, 
        }
      case "hideAPOptions":
        return {
          ...state,
          showRunOptions: false,
          showCancelNav: false,
          showDesignNav: true,
          showAPOptions: false,
        }
      case "displayManualScale":
          return {
            ...state,
            showManualScale: true,
            showCancelNav: true,
            showDesignNav: false,
          }
      case "hideManualScale": 
          return {
            ...state,
            showManualScale: false,
            showCancelNav: false,
            showDesignNav: true,
          }
      case "displayDesignSpinner":
        return {
          ...state,
          showDesignSpinner: true
        }
      case "hideDesignSpinner":
        return {
          ...state,
          showDesignSpinner: false
        }

      case "transmitterActive":
        return {
          ...state,
          transmitterActive: true,
          scaleActive: false,
          wallsActive: false
        }
      case "transmitterInactive":
        return {
          ...state,
          transmitterActive: false,
        }
      case "scaleActive":
        return {
          ...state,
          scaleActive: true,
          transmitterActive: false,
          wallsActive: false
        }
      case "scaleInactive":
        return {
          ...state,
          scaleActive: false,
        }
      case "wallsActive":
        return {
          ...state,
          scaleActive: false,
          transmitterActive: false,
          wallsActive: true,
          showWalls: true
        }
      case "wallsInactive":
        return {
          ...state,
          wallsActive: false,
          showWalls: false
        }
      case "freezeZoom":
        return {
          ...state, 
          freezeZoom: true
        }
      case "unFreezeZoom":
        return {
          ...state, 
          freezeZoom: false
        }
      case "fitToZoom":
        return {
          ...state, 
          unzoom: true
        }
      case "removeFitToZoom":
        return {
          ...state, 
          unzoom: false
        }
      default:
        return state;
    }
  };
  
  export function useDesignNavReducer(initialState) {
    return useReducer(navReducer, initialState)
  }