import React, { useState, useEffect, useContext, useRef } from "react";
import { signOut } from "../../firebase/auth";
import { useDesignNavContext } from "../../store/DesignNavContext";
import DesignContext from "../../store/DesignContext";
import {AuthUserContext} from "../../session/AuthUserContext";
import { updateFloorplanBandwidth } from "../../utils/apis";
import logo from "../../images/logo.png";
import wifi from "../../images/wifi.svg";
import wifiDraggable from "../../images/wifi-draggable.svg";
import scaleDraggable from "../../images/crosshairs-draggable.svg";
import scaleImg from "../../images/crosshairs.svg";

// import legend from "../../images/legend.png";
// import { getWalls } from "../../utils/apis";
// import { convertURLToBase64 } from "../../utils/helpers";
import "../../styles/design/designLeftNav.css";

function DesignLeftNav() {

    const [formState, setFormState] = useState({
        freqBandChecked: "false",
        freqBand: 10,
        wallsOn: "false",
        signalOn: "false",
        notesOn: "false",
        scaleOn: "false"
    });

    const [state, dispatch] = useDesignNavContext();
    const [designState, setDesignState] = useContext(DesignContext);

    const {fileLink, lastLegend, scaleCoord, planId, projId, interfaceWallsArray, walls2DArray, binaryImgMultiplier, lastSessionInterfaceWallsArray, lastSessionWalls2DArray } = designState;

    const { showLeftLogo, showTransmitter, showFloorplans, signalState, wallsState, geoScaleState, notesState, showPropagation, showScaleCrosshairs } = state;

    const { freqBandChecked, freqBand, wallsOn, signalOn, notesOn, scaleOn } = formState;

    const [wifiDraggableImg, setWifiDraggableImg] = useState("")
    const [scaleDraggableImg, setScaleDraggableImg] = useState("")

    const {uid}= useContext(AuthUserContext);

    const scaleOnRef = useRef();
    const wallsOnRef = useRef();
    const notesOnRef = useRef();
    const bandwidthRef = useRef();

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // creating img here to ensure first dragged image is ghost img
        var wifiImg = document.createElement("img");
        wifiImg.src = wifiDraggable;
        setWifiDraggableImg(wifiImg);

        var scaleImg = document.createElement("img");
        scaleImg.src = scaleDraggable;
        setScaleDraggableImg(scaleImg);

      }, []);

      useEffect(() => {

      })

    useEffect(() => {
        if (scaleOn === "true" && scaleOnRef && scaleOnRef.current) {
            scaleOnRef.current.click();
        }

        if (wallsOn === "true" && wallsOnRef && wallsOnRef.current) {
            wallsOnRef.current.click();
        }

        if (freqBand === 20 && bandwidthRef && bandwidthRef.current) {
            bandwidthRef.current.click();
        }
        // if (notesOn && notesOnRef && notesOnRef.current) {
            //     notesOnRef.current.click();
        // }
    
        setFormState({
            freqBandChecked: "false",
            freqBand: 10,
            wallsOn: "false",
            signalOn: "false",
            notesOn: "false",
            scaleOn: "false"
        })
    }, [fileLink, scaleOnRef.current, wallsOnRef.current, bandwidthRef.current]);

    const updateStatus = async (name, value) => {

        if (showFloorplans === true) {
            return;
        }
        else if (name === "wallsOn" && value === "true") {           
            // if no 2D array, save last session values
            if (!interfaceWallsArray || !walls2DArray || interfaceWallsArray.length < 1 || walls2DArray.length < 1 ) {
                setDesignState({
                    ...designState,
                    walls2DArray: lastSessionWalls2DArray,
                    interfaceWallsArray: lastSessionInterfaceWallsArray
                })
            }
            // display walls from 2D Array
            dispatch({type: "wallsActive"})
        } 
        else if (name === "scaleOn" && value === "true") {
            dispatch({type: "displayScale"})
        }
        else if (name === "notesOn" && value === "true") {
            dispatch({type: "displayNotes"})
        } else {
            dispatch({type: "AP"})
        }
    }

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        if (value === "true") {
            setFormState({ ...formState, freqBandChecked: "false", freqBand: 10 });
            // update firebase 
            updateFloorplanBandwidth(uid, projId, planId, 10);
            //Julio added to update designState
            setDesignState({...designState, bandwidth: 10});
        } else {
            setFormState({ ...formState, freqBandChecked: "true", freqBand: 20 });
            //update firebase
            updateFloorplanBandwidth(uid, projId, planId, 20);
            //Julio added to update designState
            setDesignState({...designState, bandwidth: 20});
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        let newVal = (value === "false") ? "true" : "false";
        setFormState({ ...formState, [name]: newVal });
        updateStatus(name, newVal);
    };

    const setWifiAction = (event) => {
        dispatch({type: "transmitterActive"})
    }

    const dragStartHandler = (
        e,
        icon
      ) => {
        switch(icon) {
            case "wifi": 
                e.dataTransfer.setDragImage(wifiDraggableImg, 0, 0);
                dispatch({type: "transmitterActive"});
                break;
            case "scale":
                if (scaleCoord.length < 2) {
                    e.dataTransfer.setDragImage(scaleDraggableImg, 0, 0);
                    dispatch({type: "scaleActive"});
                }
                break;
            case "walls":
                return;
        }
        
        
        // e.dataTransfer.setDragImage(wifiDraggableImg, 0, 0);
        // setWifiAction(e);
      }

      
    return (
        <div id="design-left-nav" >
            {showLeftLogo && 
                <a onClick={signOut} href='' type="button">
                    <img id="design-logo" src={logo} alt="logo"/>
                </a>}
            {!showLeftLogo && (
                <>
                {showTransmitter && 
                    <a onDragStart={(event) => dragStartHandler(event, "wifi")}
                    onClick={(e) => e.preventDefault()} href='' type="button">
                        <img id="design-wifi-icon" src={wifi} alt="wifi icon"/>
                    </a>}
                {scaleOn=="true" && 
                    <a onDragStart={(event) => dragStartHandler(event, "scale")}
                    onClick={(e) => e.preventDefault()} href='' type="button">
                        <img id="design-wifi-icon" src={scaleImg} alt="crosshairs icon"/>
                    </a>}
                <p className="toggle-label">10-20 (Mhz)</p>
                <label id="num-val-toggle" className="switch">
                    <input type="checkbox"
                        name="freqBandChecked"
                        disabled={signalState}
                        value={freqBandChecked}
                        onChange={event => handleCheckboxChange(event)}
                        ref={bandwidthRef}
                    />
                    <span className="slider round"></span>
                </label>
                <p className="toggle-label">Walls</p>
                <label id="walls-toggle" className="switch">
                    <input type="checkbox"
                        name="wallsOn"
                        disabled={wallsState}
                        value={wallsOn}
                        onChange={event => handleChange(event)}
                        ref={wallsOnRef}
                    />
                    <span className="slider round"></span>
                </label>
                <p className="toggle-label">Geo Scale</p>
                <label id="signal-toggle" className="switch">
                    <input type="checkbox"
                        name="scaleOn"
                        disabled={geoScaleState}
                        value={scaleOn}
                        onChange={event => handleChange(event)}
                        ref={scaleOnRef}
                    />
                    <span className="slider round"></span>
                </label>
                <i className="far fa-dot-circle"></i>
                {/* <p className="toggle-label">Notes</p> */}
                {/* <label id="notes-toggle" className="switch">
                    <input type="checkbox"
                        name="notesOn"
                        disabled={notesState}
                        value={notesOn}
                        onChange={event => handleChange(event)}
                        ref={notesOnRef}
                    />
                    <span className="slider round"></span>
                </label> */}
                {(lastLegend && showPropagation) && <img id="design-legend" src={"data:image/jpg;base64,"+ lastLegend} alt="heatmap-legend"/>}
                </>
            )}
        </div>     
    );
}

export default DesignLeftNav;
