
import { createContext, useState } from 'react';

const DesignContext = createContext();

export const DesignProvider = ({ children }) => {
  
  const [designState, setDesignState] = useState({
    projId: "",
    projName: "",
    planId: "",
    planSelected: "",
    planNameToAdd: "",
    getReference: [],
    imageBinaryHeight: "",
    imageBinaryWidth: "",
    bandwidth: 10,
    walls1DArray: [],
    walls2DArray: [],
    binaryWalls: "",
    notes: "",
    binaryNotes: "",
    transmitters: [],
    binaryTransmitters: [],
    scaleCoord: [],
    binaryScaleCoord: [],
    imageReference: [],
    lastLegend: "",
    lastPrediction: "",
    predictionArea: [],
    fileLink: "",
    fileName: "",
    selectedFile: "", 
    pixelsPerMeter: 16,
    binaryImgMultiplier: 1,
    interfaceWallsArray: [],
    interfaceWallsString: "",
    lastSessionInterfaceWallsArray: [],
    lastSessionWalls2DArray: [], 
    imperialUnits: true, 
    saveForm: false,
  });
 
  return (
    <DesignContext.Provider value={[designState, setDesignState]}>
      {children}
    </DesignContext.Provider>
  );
};

export default DesignContext;

/*
func setState(newState:State){
        state = newState
        switch state{
        case .AP:
            imgContainerView.sendSubviewToBack(scannerMenu)
            imgContainerView.sendSubviewToBack(wallMainMenu)
            btnPlay.image = UIImage(named: "beta-play")
            btnPlay.isEnabled = true
            statusImage.isUserInteractionEnabled = true
            statusText.text = stateMessage[.AP]
            statusImage.image = stateIcon[.AP]
            clearNotes()
            clearWalls()
            clearSignal()
            clearGeoMarkers()
            noteSwitch.isEnabled = true
            //signalSwitch.isEnabled = true
            wallSwitch.isEnabled = true
            geoCodeSwitch.isOn = false
            geoCodeSwitch.isEnabled = true
            updateTransmitterViews()
            setMenuBarState(noPlan: false)
            gMapButton.isEnabled = false
           
        case .Note:
            imgContainerView.sendSubviewToBack(scannerMenu)
            imgContainerView.sendSubviewToBack(wallMainMenu)
            btnPlay.image = UIImage(named: "imgTx2")
            btnPlay.isEnabled = true
            statusImage.isUserInteractionEnabled = true
            statusText.text = stateMessage[.Note]
            statusImage.image = stateIcon[.Note]
            clearWalls()
            clearSignal()
            clearGeoMarkers()
            wallSwitch.isEnabled = false
            geoCodeSwitch.isEnabled = false
            //signalSwitch.isEnabled = false
            updateTransmitterViews()
            setMenuBarState(noPlan: false)
            gMapButton.isEnabled = false
        case .Signal:
            imgContainerView.sendSubviewToBack(wallMainMenu)
            btnPlay.image = UIImage(named: "imgTx2")
            btnPlay.isEnabled = true
            statusImage.isUserInteractionEnabled = false
            statusText.text = stateMessage[.Signal]
            statusImage.image = stateIcon[.Signal]
            clearNotes()
            clearWalls()
            clearGeoMarkers()
            noteSwitch.isEnabled = false
            wallSwitch.isEnabled = false
            updateTransmitterViews()
            setMenuBarState(noPlan: false)
            gMapButton.isEnabled = false
        case .Wall:
            imgContainerView.sendSubviewToBack(scannerMenu)
            btnPlay.image = UIImage(named: "imgTx2")
            btnPlay.isEnabled = true
            noteSwitch.isEnabled = false
            geoCodeSwitch.isEnabled = false
            //signalSwitch.isEnabled = false
            statusImage.isUserInteractionEnabled = true
            statusText.text = stateMessage[.Wall]
            statusImage.image = stateIcon[.Wall]
            clearNotes()
            clearSignal()
            clearGeoMarkers()
            updateTransmitterViews()
            setMenuBarState(noPlan: false)
            gMapButton.isEnabled = false
        case .Scale:
            imgContainerView.sendSubviewToBack(scannerMenu)
            imgContainerView.sendSubviewToBack(wallMainMenu)
            btnPlay.isEnabled = false
            statusImage.isUserInteractionEnabled = false
            statusText.text = stateMessage[.Scale]
            statusImage.image = stateIcon[.Scale]
            noteSwitch.isEnabled = false
            //signalSwitch.isEnabled = false
            wallSwitch.isEnabled = false
            geoCodeSwitch.isEnabled = false
            updateTransmitterViews()
            setMenuBarState(noPlan: false)
            gMapButton.isEnabled = false
        case .GeoScale:
            imgContainerView.sendSubviewToBack(scannerMenu)
            imgContainerView.sendSubviewToBack(wallMainMenu)
            btnPlay.isEnabled = false
            statusImage.isUserInteractionEnabled = true
            statusText.text = stateMessage[.GeoScale]
            statusImage.image = stateIcon[.GeoScale]
            noteSwitch.isEnabled = false
            //signalSwitch.isEnabled = false
            wallSwitch.isEnabled = false
            if currentFloorPlan.imageRef.count == 2{
                gMapButton.isEnabled = true
            }
            updateTransmitterViews()
            loadGeoMarkers()
            setMenuBarState(noPlan: false)
           
        case .NoPlan:
            imgContainerView.sendSubviewToBack(scannerMenu)
            imgContainerView.sendSubviewToBack(wallMainMenu)
            btnPlay.isEnabled = false
            statusImage.isUserInteractionEnabled = false
            statusText.text = stateMessage[.NoPlan]
            statusImage.image = stateIcon[.NoPlan]
            noteSwitch.isEnabled = false
            //signalSwitch.isEnabled = false
            wallSwitch.isEnabled = false
            geoCodeSwitch.isEnabled = false
            setMenuBarState(noPlan: true)
            gMapButton.isEnabled = false
        }
    }
    */
