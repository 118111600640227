import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDesignNavContext } from "../../store/DesignNavContext";
import DesignContext from "../../store/DesignContext";
import projects from "../../images/projects.svg";
import cancel from "../../images/cancel.svg";
import "../../styles/topNav.css";
import "../../styles/design/floorplan/floorplanNav.css";
import "../../styles/design/designTopNav.css";

function CancelNav(props) {

  const [state, dispatch] = useDesignNavContext();

  const [designState, setDesignState] = useContext(DesignContext);

  const {fileLink, projName} = designState;

  const {showManualScale, showRunOptions, showAPOptions} = state;

    const handleCancel = (e)=>{
      e.preventDefault();
      if (showManualScale) {
        dispatch({type: "hideManualScale"});
      } else if (showRunOptions) {
        dispatch({type: "hideRunOptions"});
      } else if (showAPOptions) {
        dispatch({type: "hideAPOptions"});
      } else if (fileLink) {
        setDesignState({...designState, selectedFile: "", planNameToAdd: ""});
        dispatch({type: "AP"});
      } else {
        setDesignState({...designState, selectedFile: "", planNameToAdd: ""});
        dispatch({type: "noPlan"});
      }
    }

    return (
      <header id="floorplan-top-nav" className="top-nav">
        <span id="floorplan-title">{projName}</span>
        <div className="nav-right-icon-div">
          <a className="nav-icon-container" onClick={(e) => handleCancel(e)} href='' type="button">
              <img className="nav-icon-link" src={cancel} alt="cancel-icon"/>
              <span id="floorplan-cancel-text" className="nav-icon-text">Cancel</span>
          </a>
          <Link className="nav-icon-container" to="/project">
            <img className="nav-icon-link" src={projects} alt="projects-icon"/> 
            <span id="floorplan-projects-text" className="nav-icon-text">Projects</span>
          </Link>
        </div>
      </header>
    );
}

export default CancelNav;