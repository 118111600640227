import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router";
import DesignContext from "../../store/DesignContext";
import { useDesignNavContext } from "../../store/DesignNavContext";
import {AuthUserContext} from "../../session/AuthUserContext";
import { updateFloorplanScale } from "../../utils/apis";

function ManualScale(props) {

  const [message, setMessage] = useState({ messageText: "", messageColor: "" });
 
  const { messageText, messageColor } = message;

  const [designState, setDesignState] = useContext(DesignContext);
  const [scaleUnits, setScaleUnits] = useState("Feet")
  const [metersInRequestedUnits, setMetersInRequestedUnits] = useState(props.metersInRequestedUnits.toFixed(4))

  const {projId, planId, binaryScaleCoord, imperialUnits} = designState;
  const [state, dispatch] = useDesignNavContext();

  const {uid}= useContext(AuthUserContext);

  useEffect( async () => {
    // when first get to page, set current scale value
    if (!imperialUnits) {
      setScaleUnits("Meters")
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleChange = (event) => {
    const {value} = event.target;
    setMetersInRequestedUnits(value)
  };


  const handleSubmit = async(event) => {
    event.preventDefault();
    let meters;
    if (imperialUnits) {
      meters = metersInRequestedUnits / 3.28084;
    } else {
      meters = metersInRequestedUnits;
    }


    const pixelsPerMeter = props.pixels/meters;
    setDesignState({...designState, pixelsPerMeter})
    dispatch({type: "hideManualScale"})


    //update firebase
    updateFloorplanScale(uid, projId, planId, pixelsPerMeter);
  }

  const handleCancel = (e)=>{
    e.preventDefault();
    dispatch({type: "hideManualScale"});
  }

  return (
    <div className="modal-container">
        <div className="floorplans-card">
            <div className="floorplans-header">
                <span>Scale in {scaleUnits}</span>
            </div>
            <form onSubmit={handleSubmit}>
              <input
              className="floorplans-input"
              type="text"
              name="floorplanName"
              value={metersInRequestedUnits}
              onChange={handleChange}
            />
            <button type="submit" className="floorplans-submit">
              Save
            </button>
          </form>
          {/* <div className="floorplan-list-card">
              <a className="floorplan-list-summary blue-link"  href="" type="button">
                  <p className="floorplan-list-name">Use Map</p>
              </a>
          </div> */}
          <div onClick={handleCancel} className="design-modal-footer">
              <a className="design-cancel-form" href="">
                  Cancel
              </a>
          </div>
        </div>
    </div>
)
}

export default ManualScale;
