
import axios from "axios";

export const getFloorplansByProjId = async (user, projId) => {
    try {
        const {data} = await axios
            .get("/api/floorplans/project/" + projId, {
                headers: {
                    'source': user
                }
        });
        return data ? data : false;
    } catch (err) {
        throw err;
    }
}

export const postFloorplan = async (floorplanToSave) => {
    try {
        const floorplanData = await axios.post("/api/floorplans", floorplanToSave);
        if (floorplanData) {
            return floorplanData.data;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
}

export const updateFloorplanTransmitters = async (user, projId, planId, binaryTransmitters) => {

    let updatedBinaryTransmittersCopy=[];

    for (let i =0; i<binaryTransmitters.length;i++) {
        updatedBinaryTransmittersCopy.push([...binaryTransmitters[i]])
    }

    let updatedBinaryTransmitters = updatedBinaryTransmittersCopy.map((transmitter) => {
        // not needed since we are not substracting 20 when loading from FB
        // transmitter[2] = transmitter[2] +20;
        // transmitter[3] = transmitter[3] +20;
        return transmitter;
    })

    const floorplanToUpdate = {
        user, 
        projId,
        planId,
        updatedBinaryTransmitters
    }
    try {
        const floorplanData = await axios.put("/api/floorplans/transmitters", floorplanToUpdate);
        if (floorplanData) {
            return floorplanData;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
}

export const updateFloorplanScale = async (user, projId, planId, scale) => {
   const floorplanToUpdate = {
        user, 
        projId,
        planId,
        scale
    }
    try {
        const floorplanData = await axios.put("/api/floorplans/scale", floorplanToUpdate);
        if (floorplanData) {
            return floorplanData;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
}

export const updateFloorplanScaleCoord = async (user, projId, planId, binaryScaleCoord) => {

    let updatedBinaryScaleCopy=[];

    for (let i =0; i<binaryScaleCoord.length;i++) {
        updatedBinaryScaleCopy.push([...binaryScaleCoord[i]])
    }

    let updatedBinaryScaleCoord = updatedBinaryScaleCopy.map((scale) => {
        return [scale[0]+15, scale[1]+15]
    })
    
    const floorplanToUpdate = {
        user, 
        projId,
        planId,
        binaryScaleCoord : updatedBinaryScaleCoord
    }

    try {
        const floorplanData = await axios.put("/api/floorplans/scale/coordinates", floorplanToUpdate);
        if (floorplanData) {
            return floorplanData;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
}

export const updateFloorplanBandwidth = async (user, projId, planId, bandwidth) => {
    const floorplanToUpdate = {
        user, 
        projId,
        planId,
        bandwidth
    }
    try {
        const floorplanData = await axios.put("/api/floorplans/bandwidth", floorplanToUpdate);
        if (floorplanData) {
            return floorplanData;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
}

export const deleteFloorplanById = async (user, projId, floorplanId) => {
    try {
        const deletedPlan = await axios
            .delete("/api/floorplans/" + floorplanId, {
            headers: {
                'source': user
            },
            data: {
                projId
            }
        });
        if (deletedPlan && deletedPlan.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
}
