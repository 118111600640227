import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDesignNavContext } from "../../../store/DesignNavContext";
import DesignContext from "../../../store/DesignContext";
import {AuthUserContext} from "../../../session/AuthUserContext";
import projects from "../../../images/projects.svg";
import crop from "../../../images/crop.svg";
import rotate from "../../../images/rotate.svg";
import cancel from "../../../images/cancel.svg";
import save from "../../../images/save.svg";
import alert from "../../../images/alert.svg";
import "../../../styles/topNav.css";
import "../../../styles/design/floorplan/floorplanNav.css";
import "../../../styles/design/designTopNav.css";

import { postFileData, postFloorplan } from "../../../utils/apis";

function DesignNav(props) {

  const [state, dispatch] = useDesignNavContext();

  const [designState, setDesignState] = useContext(DesignContext);

  const {projId, projName, planNameToAdd, selectedFile, fileLink} = designState;

  const {uid}= useContext(AuthUserContext);

  useEffect( async () => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

    const handleCrop = (e) => {
      e.preventDefault();
    }

    const handleRotate = (e) => {
      e.preventDefault();
    }

    const handleSaveFloorplan = async (e) => {
      e.preventDefault();
      // save to aws
      const fileSaved = await submitFileData();
      // get file link and file name from aws
      const fileLink = fileSaved["link"];
      const fileName = fileSaved.name;

      // save plan name, file name, and file link
      const id = await postFloorplan({
        uid,
        projId,
        fileName,
        fileLink,
        planNameToAdd
      });

      const newFileData = {
        planId: id,
        fileLink: fileLink,
        selectedFile: "",
        planNameToAdd: "",
        geoReference: [], 
        lastLegend: "", 
        bandwidth: 10,
        binaryScaleCoord: [],
        lastPrediction: "",
        predictionArea: [], 
        pixelsPerMeter: 16, 
        scaleCoord: [],
        binaryTransmitters: [],
        transmitters: [],
        walls1DArray: [],
        walls2DArray: [],
        interfaceWallsArray: [],
        lastSessionWalls2DArray: [],
        lastSessionInterfaceWallsArray: [],
        interfaceWallsString: ""
      }
      setDesignState({...designState, ...newFileData})
      dispatch({type: "AP"});
    }

    const submitFileData = async () => {
      const data = new FormData();
      data.append("file", selectedFile);
      const fileData = await postFileData(data);
      return fileData;
    };

    const handleCancel = (e)=>{
      e.preventDefault();
      setDesignState({...designState, selectedFile: "", planNameToAdd: ""});
      if (fileLink) {
        dispatch({type: "AP"});
      } else {
        dispatch({type: "noPlan"});
      }
      // if there is a file link, AP state
      // if no file link, noPlan
//onClick={(e) => props.handleNavState(e, "hideFloorplans")
    }

    return (
        <header id="floorplan-top-nav" className="top-nav">
            <span id="floorplan-title">{projName}</span>
            {selectedFile && <div className="nav-left-icon-div">
              <img className="nav-alert-icon" src={alert} alt="alert-icon"/>
              <span className="nav-alert-text">Floorplan not saved</span>
            </div>}
            <div className="nav-right-icon-div">
              {selectedFile && <>
              <a className="nav-icon-container" onClick={(e) => handleSaveFloorplan(e)} href='' type="button">
                  <img className="nav-icon-link" src={save} alt="save-icon"/>
                  <span id="floorplan-save-text" className="nav-icon-text">Save</span>
              </a>
              {/* <a className="nav-icon-container" onClick={(e) => handleCrop(e)} href='' type="button">
                  <img className="nav-icon-link" src={crop} alt="crop-icon"/>
                  <span id="floorplan-crop-text" className="nav-icon-text">Crop</span>
              </a>
              <a className="nav-icon-container" onClick={(e) => handleRotate(e)} href='' type="button">
                  <img className="nav-icon-link" src={rotate} alt="rotate-icon"/>
                  <span id="floorpan-rotate-text" className="nav-icon-text">Rotate</span>
              </a> */}
              <div id="left-rotate-divider" className="vertical-line"></div>
              </>}

              {fileLink && <a className="nav-icon-container" onClick={(e) => handleCancel(e)} href='' type="button">
                  <img className="nav-icon-link" src={cancel} alt="cancel-icon"/>
                  <span id="floorplan-cancel-text" className="nav-icon-text">Cancel</span>
              </a>}
              <Link className="nav-icon-container" to="/project">
                <img className="nav-icon-link" src={projects} alt="projects-icon"/> 
                <span id="floorplan-projects-text" className="nav-icon-text">Projects</span>
              </Link>
            </div>
        </header>
    );
}

export default DesignNav;