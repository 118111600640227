import React from "react";
import { AuthUserContext} from "./AuthUserContext";
import { auth } from '../firebase/auth';
import { useHistory } from 'react-router-dom';

const withAuthorization = (condition) => (Component) => {
    function WithAuthorization() {

        const history = useHistory();

        auth.onAuthStateChanged(authUser => {
            if (!condition(authUser)) {
                history.replace("/");
            };
        });

        return (
            <AuthUserContext.Consumer>
                {user => user ? <Component uid={user.uid}/> : null}
            </AuthUserContext.Consumer>
        ); 
    }
    return WithAuthorization;
}

export default withAuthorization;



