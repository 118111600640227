import axios from "axios";
import { getShortDate } from "../helpers";

export const getAllProjects = async (user) => {
    try {
        const {data} = await axios
        .get("/api/projects", {
            headers: {
                'source': user
            }
        });
        if (data) {
            data.forEach((proj) => {
                if (proj.date) {
                    proj.date = getShortDate(proj.date);
                }
            })
            return data;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};

export const getProjectById = async (user, projId) => {
    try {
        const {data} = await axios
            .get("/api/projects/" + projId, {
                headers: {
                    'source': user
                }
        });
        if (data) {
            if (data.date) {
                data.date = getShortDate(data.date);
            }
            data.unitImperial = (data.unitImperial === true) ? "1" : "0";
            return data;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};

export const postProjectData = async (projToSave) => {
    try {
        const projectData = await axios.post("/api/projects", projToSave);
        if (projectData) {
            return projectData;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};

export const deleteProjectById = async (user, projId) => {
    try {
        const deletedProject = await axios
            .delete("/api/projects/" + projId, {
            headers: {
                'source': user
            }
        });
        if (deletedProject && deletedProject.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
}