import { types } from './zoomActionCreators';

const ZOOM_FACTOR = 0.01;
const ZOOM_FACTOR_IN = 1 + ZOOM_FACTOR;
const ZOOM_FACTOR_OUT = 1 - ZOOM_FACTOR;


export const initialState = {
  translateX: 0,
  translateY: 0,
  prevMouseX: 0,
  prevMouseY: 0,
  scale: 1,
  deltaY: 0,
  transX:0,
  transY:0

};

export const reducer = (state, action) => {
  switch(action.type) {

    case types.UNZOOM_AND_PAN:
      return {
        ...state,
        translateX: 0,
        translateY: 0,
        prevMouseX: 0,
        prevMouseY: 0,
        scale: 1,
        deltaY: 0,
        transX:0,
        transY:0
      };


    case types.PAN_START:
      return {
        ...state,
        prevMouseX: action.clientX,
        prevMouseY: action.clientY,
      };

    case types.PAN:
      const deltaMouseX = action.clientX - state.prevMouseX;// compares mouse position to previous position = distance mouse moved
      const deltaMouseY = action.clientY - state.prevMouseY;
      return {
        ...state,
        translateX: state.translateX + deltaMouseX,
        translateY: state.translateY + deltaMouseY,
        prevMouseX: action.clientX,
        prevMouseY: action.clientY,
        transX: (state.translateX + deltaMouseX),
        transY: (state.translateY + deltaMouseY)
        
      };


    case types.ZOOM:
      const scaledTranslate = getScaledTranslate(state, action.zoomFactor);
      const mousePositionOnScreen = { x: action.clientX, y: action.clientY };
      const zoomOffset = getZoomOffset(action.containerRect, mousePositionOnScreen, action.zoomFactor);
      const trans = getTranslation(action.containerRect, mousePositionOnScreen, action.zoomFactor);
      let newTranslateX = scaledTranslate.x + zoomOffset.x;
      let newTranslateY = scaledTranslate.y + zoomOffset.y;
      let trueTransX = newTranslateX * state.scale 
      let trueTransY = newTranslateY * state.scale
      return {
        ...state,
        scale: state.scale * action.zoomFactor,
        translateX: scaledTranslate.x + zoomOffset.x,
        translateY: scaledTranslate.y + zoomOffset.y,
        transX: (scaledTranslate.x + zoomOffset.x),
        transY: (scaledTranslate.y + zoomOffset.y)
      };


    case "REVERSE_SCALE_XY":
      const unscaledTranslate = getScaledTranslate(state, action.zoomFactor);
      const mousePositionOnScreenXY = { x: action.clientX, y: action.clientY };
      const zoomOffsetXY = getZoomOffset(action.containerRect, mousePositionOnScreenXY, action.zoomFactor);
      return {
        scale: state.scale * action.zoomFactor,
        translateX: unscaledTranslate.x + zoomOffsetXY.x,
        translateY: unscaledTranslate.y + zoomOffsetXY.y,
      };

      
    default:
      return state;
  }
};


/*
{
  type: types.GET_UNSCALED_XY,
  zoomFactor: - event.deltaY < 0 ? ZOOM_FACTOR_IN : ZOOM_FACTOR_OUT,
  clientX: event.clientX,
  clientY: event.clientY,
  containerRect: containerRect,
};

*/
//calculate the distance of the mouse pointer to the center of the container. The zoom offset is now the difference between this distance and its scaled version.
// keep red dot (scale origin) where is by moving the picture by the distance b/t the center of the image container and the scaled image
const getZoomOffset = (containerRect, mousePositionOnScreen, zoomFactor) => {
  const zoomOrigin = {
    x: mousePositionOnScreen.x - containerRect.left,
    y: mousePositionOnScreen.y - containerRect.top,
  }
  const currentDistanceToCenter = {
    x: containerRect.width / 2 - zoomOrigin.x,
    y: containerRect.height / 2 - zoomOrigin.y,
  };
  const scaledDistanceToCenter = {
    x: currentDistanceToCenter.x * zoomFactor,
    y: currentDistanceToCenter.y * zoomFactor,
  }
  const zoomOffset = {
    x: scaledDistanceToCenter.x - currentDistanceToCenter.x,
    y: scaledDistanceToCenter.y - currentDistanceToCenter.y,
  };
  return zoomOffset;
};

const getTranslation = (containerRect, mousePositionOnScreen, zoomFactor) => {
  const currentLocation = {
    x: mousePositionOnScreen.x - containerRect.left,
    y: mousePositionOnScreen.y - containerRect.top,
  }
  const centerDistanceToViewEdge = {
    x: containerRect.width / 2,
    y: containerRect.height / 2,
  };
  const centerDistanceToImageEdge= {
    x: centerDistanceToViewEdge.x * zoomFactor,
    y: centerDistanceToViewEdge.y * zoomFactor,
  }
  const transDueToZoom= {
    x: -(centerDistanceToImageEdge.x - centerDistanceToViewEdge.x),
    y: -(centerDistanceToImageEdge.y - centerDistanceToViewEdge.y),
  }
  const trans = {
    x: transDueToZoom.x,
    y: transDueToZoom.y
  };
  return trans;
};





const reverseZoomOffset = (containerRect, mousePositionOnScreen, zoomFactor) => {
  const zoomOrigin = {
    x: mousePositionOnScreen.x - containerRect.left,
    y: mousePositionOnScreen.y - containerRect.top,
  }
  const currentDistanceToCenter = {
    x: containerRect.width / 2 - zoomOrigin.x,
    y: containerRect.height / 2 - zoomOrigin.y,
  };
  const scaledDistanceToCenter = {
    x: currentDistanceToCenter.x * zoomFactor,
    y: currentDistanceToCenter.y * zoomFactor,
  }
  const zoomOffset = {
    x: scaledDistanceToCenter.x - currentDistanceToCenter.x,
    y: scaledDistanceToCenter.y - currentDistanceToCenter.y,
  };
  return zoomOffset;
};

//When a user first moves the image and then zooms in/out at any point we need to scale the previous translation vectors as well. 
const getScaledTranslate = (state, zoomFactor) => ({
  x: state.translateX * zoomFactor,
  y: state.translateY * zoomFactor,
});

const getUnscaledTranslate = (state, zoomFactor) => ({
  x: state.translateX / zoomFactor,
  y: state.translateY / zoomFactor,
});