import React, { useEffect, useLayoutEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { auth, logout } from "../../firebase/auth";
import wifiIcon from "../../images/wifi.svg";
import scaleIcon from "../../images/crosshairs.svg";
import wifiDraggable from "../../images/wifi-draggable.svg";
import scaleDraggable from "../../images/crosshairs-draggable.svg";
import Message from "../Message";
import { db } from "../../firebase/db";
import withAuthorization from '../../session/withAuthorization';
import {AuthUserContext} from "../../session/AuthUserContext";
import { getProjectById, getFloorplansByProjId, updateFloorplanTransmitters, updateFloorplanScaleCoord } from "../../utils/apis";
import DesignTopNav from "./DesignTopNav";
import DesignLeftNav from "./DesignLeftNav";
import RunOptions from "./RunOptions";
import APOptions from "./APOptions";
import ManualScale from "./ManualScale";
import FloorPlanMenu from "./Floorplan/FloorplanModal";
import "../../styles/design/design.css";
import DesignContext from "../../store/DesignContext";
import { useDesignNavContext } from "../../store/DesignNavContext";
import Spinner from "../Spinner";
import {usePanAndZoom} from "../../hooks/usePanAndZoom";
import StripQuotes from "concurrently/src/command-parser/strip-quotes";
// This is a comment for commit purpose only
function Design(props) {
  const [spinnerStyle, setSpinnerStyle] = useState(null)
  const [messageStyle, setMessageStyle] = useState(null)
  const [leftNavWidth]  = useState(130);
  const [wifiDraggableImg, setWifiDraggableImg] = useState("")
  const [wifiScaleImg, setScaleDraggableImg] = useState("")

  const [moveTransmitterActive, setMoveTransmitterActive] = useState(false);
  const [moveScaleActive, setMoveScaleActive] = useState(false);

  const {containerRef, onMouseDownPan, removePanListeners, onWheel, translateX, translateY, scale, deltaY, getUnscaledXY, transX, transY, unzoomAndPan} = usePanAndZoom();

  const [imageDivStyle, setImageDivStyle]=useState({width: "auto", height: "auto"});

  const [binaryImgMultiplier, setBinaryImgMultiplier]  = useState(1);
  const [imageBinaryHeight, setImageBinaryHeight]  = useState("");
  const [imageBinaryWidth, setImageBinaryWidth]  = useState("");
  const [scaleDisplayCoord, setScaleDisplayCoord] = useState({x: "", y: ""});
  const [scaleUnits, setScaleUnits] = useState(" ft");
  const [metersInRequestedUnits, setMetersInRequestedUnits] = useState("");
  const [scaleMeters, setScaleMeters] = useState("");
  const [binaryScaleDistance, setBinaryScaleDistance] = useState("");
  const [clickedIcon, setClickedIcon] = useState(null)

  const [state, dispatch] = useDesignNavContext();

  const {transmitterActive, scaleActive, showWalls, showNotes, showRunOptions, showAPOptions, showCancelNav, showPropagation, showScale, showManualScale, freezeZoom, showDesignSpinner, unzoom} = state;

  const [designState, setDesignState] = useContext(DesignContext);

  const {projId, projName, planId, selectedFile, fileLink, walls, notes, binaryTransmitters, transmitters, walls2DArray, lastPrediction, lastLegend, scaleCoord, binaryScaleCoord, pixelsPerMeter, imperialUnits } = designState;

  const bodyRef = useRef();
  const floorplanRef = useRef();

  const {uid}= useContext(AuthUserContext);
  const [message, setMessage] = useState(null);

  const apSize = 40;

  useEffect( async() => {
    if (projId) {
      const { name, unitImperial } = await getProjectById(props.uid, projId);
      let imperialUnits = true;
      if (unitImperial === "0") {
        imperialUnits = false;
      } 
      if (!imperialUnits) {
        setScaleUnits(" m");
      }
      setDesignState({...designState, projName: name, imperialUnits});

      //https://pretagteam.com/question/react-onwheel-handler-cant-preventdefault-because-its-a-passive-event-listenever
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [projId]);

  useEffect( async() => {
    if (!freezeZoom) {
      containerRef.current.addEventListener("wheel", onWheel, {passive: false})
    } else {
      containerRef.current.removeEventListener("wheel", onWheel, {passive: false})
    }
  }, [freezeZoom])

  useEffect( async() => {
    //if 2 scales
    if (scaleCoord && scaleCoord.length === 2) {
      // get distance of line between them
      const distance = getDistanceBetweenPoints(scaleCoord[0], scaleCoord[1]);
      const binaryDistance = distance/binaryImgMultiplier;
      // calculate scale
      const scaleMeters = binaryDistance/pixelsPerMeter;

      setScaleMeters(scaleMeters)
      setBinaryScaleDistance(binaryDistance);

      if (scaleUnits === " m") {
        setMetersInRequestedUnits(scaleMeters);
      } else {
        setMetersInRequestedUnits(scaleMeters * 3.28084);
      }

      // get average x average y of scales
      let xAvg = (scaleCoord[0].x + scaleCoord[1].x)/2;
      let yAvg = (scaleCoord[0].y + scaleCoord[1].y)/2;

      // if diff of x smaller, it is vertical
      if (Math.abs(scaleCoord[0].x - scaleCoord[1].x) < Math.abs(scaleCoord[0].y - scaleCoord[1].y)) {
        xAvg += 3;
        yAvg -=15;
      } else {
        //if diff of x smaller, it is horizontal
        xAvg -= 50;
        yAvg += 3;
      }
      setScaleDisplayCoord({x: xAvg, y: yAvg})
   

    }
  }, [scaleCoord, pixelsPerMeter]);

  useLayoutEffect(() => {
    let imageBinaryHeight; 
    let imageBinaryWidth;
    let zoom=1;
    
    // get height and width of the image
    const imgObj = new Image();
    imgObj.src = fileLink;
    
    imgObj.onload= function(){
      imageBinaryWidth = this.width; 
      imageBinaryHeight = this.height;
      
      // get height and width of body container
      let bodyHeight = bodyRef.current.clientHeight;
      let bodyWidth = bodyRef.current.clientWidth - leftNavWidth;

      // get zoom float
      if (imageBinaryWidth > imageBinaryHeight) {
        zoom = bodyWidth / imageBinaryWidth ;
      } else {
        zoom = bodyHeight / imageBinaryHeight;
      }

      let newHeight = (imageBinaryHeight * zoom);
      let newWidth = imageBinaryWidth * zoom;
      if (zoom != binaryImgMultiplier) {
        
        setBinaryImgMultiplier(zoom);
        // zoom image to fit body container
        setImageDivStyle({width: newWidth + "px", height: newHeight + "px"})
        setImageBinaryHeight(imageBinaryHeight);
        setImageBinaryWidth(imageBinaryWidth);
      }

      
      if ((binaryTransmitters && binaryTransmitters.length > 0) || (binaryScaleCoord && binaryScaleCoord.length > 0)) {

        let updatedTransmitters = [];
        let updatedScaleCoord = [];
        let objToUpdate = {}
        let update = false;


        
        if (binaryTransmitters && binaryTransmitters.length > 0) {
          console.log("Drawing APS")
          updatedTransmitters = binaryTransmitters.map( value => {
            return {
              style: {position: "absolute", height: "40px", width: "40px", zIndex: "1000", top: (value[3] * binaryImgMultiplier - apSize/2) + "px", left: (value[2] * binaryImgMultiplier - apSize/2) + "px"},
              draggable: false, 
              x: value[2] * binaryImgMultiplier, 
              y: value[3] * binaryImgMultiplier,
              label: value[4]
            }
          });



          if (transmitters && transmitters.length > 0) {
            // transmitters diferent
            if (transmitters[0].x !== updatedTransmitters[0].x || transmitters[0].y !== updatedTransmitters[0].y) {
              objToUpdate.transmitters = updatedTransmitters;
              update = true;
            }
          } else {
            // no transmitters
            objToUpdate.transmitters = updatedTransmitters;
            update = true;
          }
        }

        if (binaryScaleCoord && binaryScaleCoord.length > 0) {
          updatedScaleCoord = binaryScaleCoord.map( value => {
            return {
              style: {position: "absolute", height: "30px", width: "30px", zIndex: "1000", top: value[1] * binaryImgMultiplier, left: value[0] * binaryImgMultiplier},
              draggable: false,
              x: value[0] * binaryImgMultiplier +15,
              y: value[1] * binaryImgMultiplier +15
            }
          });

          if (scaleCoord && scaleCoord.length > 0) {
            // scale coord different
            if (scaleCoord[0].x !== updatedScaleCoord[0].x || scaleCoord[0].y !== updatedScaleCoord[0].y) {
              objToUpdate.scaleCoord = updatedScaleCoord;
              update = true;
            }
          } else {
            // no scale coord
            objToUpdate.scaleCoord = updatedScaleCoord;
            update = true;
          }
        }

        if (update && Object.keys(objToUpdate).length !== 0) {
          setDesignState({...designState, ...objToUpdate })
        }
      }


      // 

    }// end of onload 
  }); 

  useEffect(()=>{
    setDesignState({...designState, imageBinaryHeight, imageBinaryWidth});
  }, [imageBinaryHeight, imageBinaryWidth]);

  useEffect( () => {
    if (unzoom) {
      unzoomAndPan();
      dispatch({type: "removeFitToZoom"})
    }
  },[unzoom])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // creating img here to ensure first dragged image is ghost img
    var wifiImg = document.createElement("img");
    wifiImg.src = wifiDraggable;
    setWifiDraggableImg(wifiImg);

    var scaleImg = document.createElement("img");
    scaleImg.src = scaleDraggable;
    setScaleDraggableImg(scaleImg);

  }, []);

  useEffect(() => {
    if (floorplanRef && floorplanRef.current) {
      const parentContainerWidth = floorplanRef.current.getBoundingClientRect().width;
      const parentContainerHeight = floorplanRef.current.getBoundingClientRect().height;
      const spinnerTop = parentContainerHeight/2 - 20;
      const spinnerLeft = parentContainerWidth/2 - 20 + 130;
      const messageLeft = parentContainerWidth/2 -200;
      setSpinnerStyle({left: `${spinnerLeft}px`, top: `${spinnerTop}px`})
      setMessageStyle({position: "absolute", width: "400px", left: `${messageLeft}px`, top: "0px"})
    }
  },[floorplanRef.current, imageDivStyle]);

  const handleWheel = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const getDistanceBetweenPoints = (ptA, ptB) => {
    let xDist = ptA.x - ptB.x;
    let yDist = ptA.y - ptB.y;
    return Math.sqrt(xDist * xDist + yDist * yDist);
  }

  const getXY = (e, halfIconSize)=> {
    let unscaledXY = getUnscaledXY({
      lastDeltaY: deltaY,
      clientX: e.clientX,
      clientY: e.clientY
    }, imageBinaryHeight * binaryImgMultiplier, imageBinaryWidth * binaryImgMultiplier, halfIconSize );
    unscaledXY["clientYReversed"] = unscaledXY["clientYReversed"] - halfIconSize;
    unscaledXY["clientXReversed"] = unscaledXY["clientXReversed"] - halfIconSize;
    return unscaledXY;
  }

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const setDState = (binTrans,top,left,label) => {
    // setDesignState({...designState, binaryTransmitters: updatedBinaryTransmitters, transmitters: [...transmitters, {
    //   style: {position: "absolute", height: "40px", width: "40px", zIndex: "1000", top: ( unscaledXY.clientYReversed), left: (unscaledXY.clientXReversed)},
    //   draggable: false, x: unscaledXY.clientXReversed, y: unscaledXY.clientYReversed, label
    // }]})
  }






  const dropHandler = (e) => {
    e.preventDefault();
    
    if (transmitterActive) {
      const unscaledXY = getXY(e, 0);
      const label = `AP ${binaryTransmitters.length +1}`;
     
      // binaryTransmitters format, [type, power, x, y, label]
      // default type is 1; default power is 23
      const updatedBinaryTransmitters = [...binaryTransmitters, [1, 23, unscaledXY.clientXReversed / binaryImgMultiplier, unscaledXY.clientYReversed / binaryImgMultiplier, label] ]

      setDesignState({...designState, binaryTransmitters: updatedBinaryTransmitters, transmitters: [...transmitters, {
        style: {position: "absolute", height: "40px", width: "40px", zIndex: "1000", top: ( unscaledXY.clientYReversed - apSize/2), left: (unscaledXY.clientXReversed - apSize/2)},
        draggable: false, x: unscaledXY.clientXReversed, y: unscaledXY.clientYReversed, label
      }]})
      dispatch({type: "transmitterInactive"})

      //save updated binary transmitters to firebase
      updateFloorplanTransmitters(uid, projId, planId, updatedBinaryTransmitters);

    } else if (moveTransmitterActive) {
      console.log("move transmitter handler");
      const unscaledXY = getXY(e, 0);
      const index = e.dataTransfer.getData("transmitterIdx")

      let binaryTransmittersCopy = [...binaryTransmitters]

      binaryTransmittersCopy[index][2] = unscaledXY.clientXReversed / binaryImgMultiplier;
      binaryTransmittersCopy[index][3] = unscaledXY.clientYReversed / binaryImgMultiplier;

      const label = binaryTransmittersCopy[index][4]

      let transmittersCopy = [...transmitters]

      transmittersCopy[index]= {
        style: {position: "absolute", height: "40px", width: "40px", zIndex: "1000", top: ( unscaledXY.clientYReversed - apSize/2), left: (unscaledXY.clientXReversed - apSize/2)},
        draggable: false, x: unscaledXY.clientXReversed, y: unscaledXY.clientYReversed, label
      }

      setDesignState({...designState, binaryTransmitters: binaryTransmittersCopy, transmitters: transmittersCopy})

      setMoveTransmitterActive(false);
      
      //save updated binary transmitters to firebase
      updateFloorplanTransmitters(uid, projId, planId, binaryTransmittersCopy);
      
    } else if (scaleActive) {
      const unscaledXY = getXY(e, 15);
      const updatedBinaryScaleCoord = [...binaryScaleCoord, [unscaledXY.clientXReversed / binaryImgMultiplier, unscaledXY.clientYReversed / binaryImgMultiplier] ]
      setDesignState({...designState, binaryScaleCoord: updatedBinaryScaleCoord, scaleCoord: [...scaleCoord, {
        style: {position: "absolute", height: "30px", width: "30px", zIndex: "1000", top: ( unscaledXY.clientYReversed), left: (unscaledXY.clientXReversed)},
        draggable: false,
        x: unscaledXY.clientXReversed +15,
        y: unscaledXY.clientYReversed +15
      }]})

      if (updatedBinaryScaleCoord.length === 2) {
        updateFloorplanScaleCoord(uid, projId, planId, updatedBinaryScaleCoord);
      }
      dispatch({type: "scaleInactive"})

    } else if (moveScaleActive) {
      const unscaledXY = getXY(e, 15);
      const index = e.dataTransfer.getData("scaleIdx");
      
      // copy scale coord state
      let currentScaleCoord = scaleCoord.map( (c) => {
        return c;
      });
      // delete original scale icon
      currentScaleCoord.splice(index, 1);
      // copy binary scale state
      let currentBinaryScaleCoord = binaryScaleCoord.map( (c) => {
        return c;
      });
      // delete binary scale icon
      currentBinaryScaleCoord.splice(index, 1);
      const updatedBinaryScaleCoord = [...currentBinaryScaleCoord, [unscaledXY.clientXReversed / binaryImgMultiplier, unscaledXY.clientYReversed / binaryImgMultiplier] ]

      // create new binary scale icon
      setDesignState({...designState, binaryScaleCoord: updatedBinaryScaleCoord, scaleCoord: [...currentScaleCoord, {
        style: {position: "absolute", height: "30px", width: "30px", zIndex: "1000", top: ( unscaledXY.clientYReversed), left: (unscaledXY.clientXReversed)},
        draggable: false,
        x: unscaledXY.clientXReversed +15,
        y: unscaledXY.clientYReversed +15
      }] })

      setMoveScaleActive(false);

      if (updatedBinaryScaleCoord.length === 2) {
        updateFloorplanScaleCoord(uid, projId, planId, updatedBinaryScaleCoord);
      }

    }
  };

  const handleMouseDown = (e) => {
    
    if (!freezeZoom) {
      onMouseDownPan(e);
    }

    // if on Transmitter Icon
    if ( e.target.attributes && e.target.attributes.class && (e.target.attributes.class.value === "transmitter" || e.target.attributes.class.value === "ap-label")) {
      
      let testDown = true;
      
      if (freezeZoom) {
        setTimeout(function() {
          if(testDown) {
            removePanListeners();
            // make particular transmitter icon draggable
            // get index of moved tranmitter
            const index = e.target.offsetParent.attributes["data-id"].nodeValue;
            // copy transmitters state
            let currentTransmitters = transmitters.map( (t) => {
              return t;
            });
            // make transmitter draggable
            currentTransmitters[index].draggable = true;
            // store modified position
            setDesignState({...designState, transmitters: currentTransmitters })
            setMoveTransmitterActive(true);
          } 
        }, 100);
      } else {
        setTimeout(function() {
          if(testDown) {
            removePanListeners();
            // make particular transmitter icon draggable
            // get index of moved tranmitter
            const index = e.target.offsetParent.attributes["data-id"].nodeValue;
            // copy transmitters state
            let currentTransmitters = transmitters.map( (t) => {
              return t;
            });
            // make transmitter draggable
            currentTransmitters[index].draggable = true;
            // store modified position
            setDesignState({...designState, transmitters: currentTransmitters })
            setMoveTransmitterActive(true);
          } 
        }, 500);
      }

      window.addEventListener('mouseup', function() {
        testDown = false;
      });

      window.addEventListener('mousemove', function(e) {
        testDown = false;
      });
    } 
    // if on scale icon
    else if ( e.target.attributes.class && (e.target.attributes.class.value === "scale-icon" || e.target.attributes.class.value === "scale-line")) {
      let testDown = true;
      
      setTimeout(function() {
        if(testDown) {
          // worked without wrapping methods in event listner with useCallback because in handleMouseDown adding and removing listeners before page load
          removePanListeners();

          let index;
          // if mouse down on scale line instead of scale icon
          if (e.target.attributes.class.value === "scale-line") {

            // get x and y of mouse down
            const x = e.clientX-130;
            const y = e. clientY-75;
            
            // find index of icon mouse down on
            scaleCoord.some((scale, idx)=>{
              if (x < scale.x + 15 && x > scale.x - 15 && y > scale.y - 15 && y < scale.y + 15) {
                index = idx;
                return true; // same as break when used with some()
              }
            })
          } else {
            // get index of moved scale icon
            index = e.target.attributes["data-id"].nodeValue;
          }
          // make particular scale icon draggable
          if (index) {
            // copy scale coord state
            let currentScaleCoord = scaleCoord.map( (c) => {
              return c;
            });
            // make scale icon draggable
            currentScaleCoord[index].draggable = true;
            // store modified position
            setDesignState({...designState, scaleCoord: currentScaleCoord })
            setMoveScaleActive(true);
          }
        } 
      }, 500);

      window.addEventListener('mouseup', function() {
        testDown = false;
      });

      window.addEventListener('mousemove', function(e) {
        testDown = false;
      });
    }
  }

  const dragStartHandler = (
    e,
    iconType
  ) => {
    switch(iconType) {
      case "wifi": 
        // get index of moved tranmitter
        const transmitterIdx = e.target.attributes["data-id"].nodeValue;
        // copy transmitters state
        let currentTransmitters = transmitters.map( (t) => {
          return t;
        });
        let newTransmitterStyle = {...currentTransmitters[transmitterIdx].style, opacity: 0.2}
        e.dataTransfer.setData("transmitterIdx", transmitterIdx);
        currentTransmitters[transmitterIdx].style = newTransmitterStyle;
        setDesignState({...designState, transmitters: currentTransmitters })
        break;
      case "scale": 
        // get index of moved scale icon
        const scaleIdx = e.target.attributes["data-id"].nodeValue;
        // copy scale coord state
        let currentScaleCoord = scaleCoord.map( (c) => {
          return c;
        });
        let newScaleCoordStyle = {...currentScaleCoord[scaleIdx].style, opacity: 0.2}
        e.dataTransfer.setData("scaleIdx", scaleIdx);
        currentScaleCoord[scaleIdx].style = newScaleCoordStyle;
        setDesignState({...designState, scaleCoord: currentScaleCoord })
        break;
      case "walls":
        break;
    }
  }

  const openManualScaleForm = () => {
    dispatch({type: "displayManualScale"})

  }

  const handleClickedTransmitter = (e) => {
    
    e.preventDefault();
    let apIdx;
    // get ap power and label
    if (e.target.nodeName === "SPAN" || e.target.nodeName === "IMG") {
      apIdx = e.target.offsetParent.attributes["data-id"].nodeValue;
    } else {
      apIdx = e.target.attributes["data-id"].nodeValue;
      
    } 
    
    const getAPPower = binaryTransmitters[apIdx][1];
    const getAPLabel = binaryTransmitters[apIdx][4];
    // get ap options div position
    const {clientXReversed, clientYReversed} = getXY(e, 0);

    setClickedIcon({x: clientXReversed, y: clientYReversed, xToDiv: e.clientX - 130, yToDiv: e.clientY - 75, power: getAPPower, label: getAPLabel, apIdx})

    dispatch({type: "displayAPOptions"})
  }

  return (
    <div id="design-container">
      <DesignTopNav />
      <DesignLeftNav />
      {showDesignSpinner && spinnerStyle && (
        <>
        <div id="design-spinner-background"></div>
        <Spinner location="design" style={spinnerStyle}/>
        </>
      )}
      <div id="design-body-container" ref= {bodyRef}>
        <div id="image-div-wrapper" ref={containerRef} onMouseDown={handleMouseDown}>
          <div ref= {floorplanRef} id="image-div" onDragOver={allowDrop} onDrop={dropHandler} style={{...imageDivStyle, transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`}}>
            {selectedFile && <img id='project-img' src={URL.createObjectURL(selectedFile)}/>}
            {(fileLink && !selectedFile) && 
              <>
                {(lastPrediction && showPropagation) && <img id="propagation-img" src={"data:image/jpg;base64,"+ lastPrediction}/>}
                <img id='project-img' src={fileLink}/>
                <div>

                  {transmitters && !showWalls && !showScale && transmitters.map((item, index) => {
                    
                    if (!item.draggable) {
                      console.log("We are updating transmitter html  draggable: ", item.draggable);
                      return (
                        <>
                        <div onClick = {(e) => handleClickedTransmitter(e)} draggable={false} style={item.style} key={index} data-id={index} data-x={item.x} data-y={item.y} >
                        <img className = "transmitter" src={wifiIcon}/>
                        <span className="ap-label">{item.label}</span>
                        </div>
                        </>
                      )
                    
                    } else {
                      console.log("We are updating transmitter html  draggable: ", item.draggable);
                      return (
                        <>
                        <div onDragStart={(event) => dragStartHandler(event, "wifi")} draggable={true} style={item.style} key={index} data-id={index} data-id={index} data-x={item.x} data-y={item.y} >
                        <img className = "transmitter" src={wifiIcon}/>
                        <span className="ap-label">{item.label}</span>
                        </div>
                        </>
                      )
                    }
                  })}

                  {showScale && scaleCoord && scaleCoord.map((item, index) => {
                    if (!item.draggable) {
                      return (
                        <img draggable={false} className = "scale-icon" style={item.style} key={index} data-id={index} src={scaleIcon}/>
                      )
                    } else {
                      return (
                        <img onDragStart={(event) => dragStartHandler(event, "scale")} draggable={true} className = "scale-icon" style={item.style} key={index} data-id={index} src={scaleIcon}/>
                      )
                    }
                  })}


                  {showScale && scaleCoord && scaleCoord.length === 2 && (
                    <>

                    <svg className="scale-line" style={ {position: "absolute", top: 0, left: 0, zIndex: 9, height: "100%", width: "100%" }}>
                        <line x1={scaleCoord[0].x} y1={scaleCoord[0].y} x2={scaleCoord[1].x} y2={scaleCoord[1].y} style={ {stroke:"rgb(255,0,0)", strokeWidth:2} } />
                    </svg>


                    {scaleDisplayCoord.x && <div onClick={openManualScaleForm} className="scale-value" style={ {position: "absolute", top: scaleDisplayCoord.y, left: scaleDisplayCoord.x, zIndex: 9, height: "30px", width: "100px", backgroundColor: "white", border: "none", color: "blue", fontSize: "20px", padding: "0 5px 0 5px"}}> { metersInRequestedUnits.toFixed(1) + scaleUnits}
                    </div>}
                    </>
                  )} 


                </div>
              </>
            }
            {(showWalls && walls2DArray.length>0) && (
              <svg style={ {position: "absolute", top: 0, left: 0, zIndex: 99999, height: "100%", width: "100%" }}>
                {walls2DArray.map((item, index) => {
                  var points = "";
                  for (let i = 1; i < item.length; i += 2) {
                     points += (item[i-1] * binaryImgMultiplier) + ", " + (item[i] * binaryImgMultiplier) + " " ;
                  } 
                  return (
                    <polyline key={index} points={points} style={ {fill:"none",stroke:"rgb(255,0,0)", strokeWidth:2} } />
                  )}
                )}
              </svg>
            )}

            {showAPOptions && < APOptions clickedIcon={clickedIcon} containerRef={containerRef}/>}
          </div>
          {showRunOptions && < RunOptions setMessage={setMessage}/>}
          {showManualScale && < ManualScale metersInRequestedUnits={metersInRequestedUnits} pixels={binaryScaleDistance}/>}
          {message && (
            <>
            <div id="message-background"></div>
            <Message style={messageStyle} message={message} setMessage={setMessage}/>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const authCondition = (authUser) => !!authUser;

export default  withAuthorization(authCondition)(Design);
