import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router";
import DesignContext from "../../../store/DesignContext";
import {AuthUserContext} from "../../../session/AuthUserContext";
import { useDesignNavContext } from "../../../store/DesignNavContext";
import FloorplanList from "./FloorplanList";
import "../../../styles/auth/auth.css";
import "../../../styles/design/floorplan/floorplanModal.css";

function FloorplanModal(props) {

  const [floorplanName, setFloorplanName] = useState("");
  const [floorplanInputStatus, setFloorplanInputStatus] = useState("disabled");
  const [state, dispatch] = useDesignNavContext();

  const [message, setMessage] = useState({ messageText: "", messageColor: "" });
 
  const { messageText, messageColor } = message;

  const {projId} = useParams();

  const history = useHistory();

  const {uid}= useContext(AuthUserContext);

  const [designState, setDesignState] = useContext(DesignContext);

  const {fileLink} = designState;

  useEffect( async () => {
    dispatch({type: "fitToZoom"})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleChange = (event) => {
    const {value} = event.target;
    if (value) {
      setFloorplanName(value);
      setFloorplanInputStatus("");
    } else {
      setFloorplanInputStatus("disabled");
    }
  };

  const handleSelectedFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setDesignState({...designState, selectedFile: file, planNameToAdd: floorplanName})
    props.handleNavState(event, "hideFloorplans")
  };

  const handleCancel = (e)=>{
    e.preventDefault();
    if (fileLink) {
      dispatch({type: "AP"});
    } else {
      setDesignState({...designState, selectedFile: "", planNameToAdd: ""});
      dispatch({type: "noPlan"});
    }
    
  }

  return (
    <div className="body-container">
      <div className="floorplans-card">
        <form>
          <div className="floorplans-header">
            <span>Floorplans</span>
          </div>
          <input
            className="floorplans-input"
            type="text"
            name="floorplanName"
            placeholder="floorplan name *"
            onChange={handleChange}
          />
          <div className="floorplans-submit">
            Add
            <input
            disabled={floorplanInputStatus}
            className="floorplans-file-input"
            type="file"
            name="fileInput"
            onChange={handleSelectedFile}
          />
          </div>
        </form>
        <FloorplanList/>

        {fileLink && (
          <div onClick={handleCancel} className="design-modal-footer">
            <a className="design-cancel-form" href="">
                Cancel
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default FloorplanModal;
