import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import "../../styles/topNav.css";
import "../../styles/auth/authNav.css";

function AuthNav() {
    return (
        <header id="auth-nav" className="top-nav">
            <Link id="auth-nav-link" to="/">
                <img id="auth-nav-logo" src={logo} alt="logo"/>
            </Link>
        </header>
    );
}

export default AuthNav;