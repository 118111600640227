import firebase from "firebase";
// firebase security, https://stackoverflow.com/questions/37482366/is-it-safe-to-expose-firebase-apikey-to-the-public/37484053#37484053

// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBs75HH0KEgBaoB9hJ38dsmzI-CrUfSTOU",
  authDomain: "visualcbrs.firebaseapp.com",
  projectId: "visualcbrs",
  storageBucket: "visualcbrs.appspot.com",
  messagingSenderId: "1002932583521",
  appId: "1:1002932583521:web:79e76a0f11ffc704467e20",
  measurementId: "G-T74JGZKWVT"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

function createId(){
  
}

export {
  firebase
};
