import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/auth/Login";
import PasswordResetForm from "./components/auth/PasswordResetForm";
import Design from "./components/Design";
import Project from "./components/Project";
import ProjectSetup from "./components/ProjectSetup";
import Account from "./components/Account";
import WithAuthentication from "./session/WithAuthentication";
import { DesignProvider } from "./store/DesignContext";
import { DesignNavProvider } from "./store/DesignNavContext";

function App() {

  const handleDrop = (event) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    return;
  }

  const handleDragOver = (event) => {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault();
    return;
  }

  return (
    <div className="app" onDrop={(e) => handleDrop(e)} onDragOver={(e)=> handleDragOver(e)}>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/password" component={PasswordResetForm} />
          <DesignProvider>
            <Route exact path="/account" component={Account} />
            <Route exact path="/project" component={Project} />
            <Route exact path="/project/setup/:id?" component={ProjectSetup} />
            <Route exact path="/design/:projId">
                  <DesignNavProvider>
                    <Design/>
                  </DesignNavProvider>
                
            </Route>
          </DesignProvider>
        </Switch>
      </Router>
    </div>
  );
}

// passes authenticated user info to App through AuthUserContext
export default WithAuthentication(App);

