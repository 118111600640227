export const getShortDate = (dateObj) => {
    const dateString = new Date(dateObj._seconds*1000).toDateString();
    const shortDate = dateString.split(" ").slice(1).join(" ");
    return shortDate;
};

// export const convertURLToBase64 = async (url) => {
    // return new Promise(function (resolve, reject) {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);

    //     reader.onload = function () { resolve(reader.result) };
    //     reader.onerror = function (error) { reject(error) };
    // });
// }

// export const convertURLToBase64 = async (url) => {
//     return new Promise(
//         function(resolve, reject) {
    
//           var img = new Image();
//           img.src = url;
//           img.setAttribute('crossOrigin', 'anonymous');
    
//           img.onload = function() {
//             var canvas = document.createElement("canvas");
//             canvas.width = img.width;
//             canvas.height = img.height;
//             var ctx = canvas.getContext("2d");
//             ctx.drawImage(img, 0, 0);
//             var dataURL = canvas.toDataURL("image/png");
//             resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
//           }
//           img.onerror = function() {
//             reject("The image could not be loaded.");
//           }
    
//         });
// }

// export const convertURLToBase64 = async (url) => {
//     const data = await fetch(url);
//     const blob = await data.blob();
//     return new Promise((resolve) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(blob); 
//       reader.onloadend = () => {
//         const base64data = reader.result;   
//         resolve(base64data);
//       }
//     });
// }

