import React, {useEffect, useContext, useState} from "react";
import { useHistory, useParams } from "react-router";
import {AuthUserContext} from "../../session/AuthUserContext";
import DesignContext from "../../store/DesignContext";
import { useDesignNavContext } from "../../store/DesignNavContext";
import "../../styles/design/runOptions.css";
import { getPropagation } from "../../utils/apis";


function RunOptions(props) {

    const {uid}= useContext(AuthUserContext);
    const [state, dispatch] = useDesignNavContext();
    const [designState, setDesignState] = useContext(DesignContext);
    

    const {projId, planId, imageBinaryHeight, imageBinaryWidth, binaryTransmitters, bandwidth, pixelsPerMeter, interfaceWallsArray, binaryImgMultiplier, lastSessionInterfaceWallsArray, lastSessionWalls2DArray } = designState;

    useEffect( async () => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
      });

    // TO SEE LAST SENT PROPAGATION, https://secureaccess.condormonitor.com:8889/propImage
    const requestPropagation = async (e, propagationType) => { 
        e.preventDefault();
        const imageScale = pixelsPerMeter;
        const s = imageScale/4;
        const power = 0; //works at 0 but not 23

        // get transmitters in needed format
        let transmitterString = "";
        console.log("binaryTransmitters", binaryTransmitters)
        let updatedBinaryTransmittersCopy=[];

        for (let i =0; i<binaryTransmitters.length;i++) {
            updatedBinaryTransmittersCopy.push([...binaryTransmitters[i]])
        }
    
        let updatedBinaryTransmitters = updatedBinaryTransmittersCopy.map((transmitter) => {
            // not needed since we are not substracting 20 when loading from FB
            // transmitter[2] = transmitter[2] +20;
            // transmitter[3] = transmitter[3] +20;
            return transmitter;
        })

        console.log("updatedBinaryTransmitters", updatedBinaryTransmitters)

        updatedBinaryTransmitters.forEach(t => {
            if (!transmitterString) {
                transmitterString += `${parseInt(t[2]/s)}, ${parseInt(t[3]/s)}, ${t[1]}`
            } else {
                transmitterString += `, ${parseInt(t[2]/s)}, ${parseInt(t[3]/s)}, ${t[1]}`
            }
        });

        console.log("transmitterString", transmitterString)

        // determine walls to use
        let unformattedWalls = interfaceWallsArray;

        if (!interfaceWallsArray || interfaceWallsArray.length <1) {
            unformattedWalls = lastSessionInterfaceWallsArray;
        }

        // get walls in needed format
        let wallsString = "";
        unformattedWalls.forEach((w, index)=>{
            const wallClone = [...w]

            const wallType = wallClone.pop();

            const wallsArr = wallClone.map((coord)=>{
                return parseInt(coord/s);
            })
            var binaryWall = "";
            for (let i = 3; i < wallsArr.length; i += 2){
                if (binaryWall !== ""){
                    binaryWall += ","
                }
                binaryWall += wallsArr[i - 3] + "," + wallsArr[i - 2] + ","  + wallsArr[i - 1] + "," + wallsArr[i] + "," +  wallType
              }
            const wall = binaryWall;


            if (index < unformattedWalls.length -1) {
                wallsString += wall + ", ";
            } else {
                wallsString += wall
            }
        })
        console.log("Bandwidth: ",bandwidth);
        // where are f, n, bw, imageScale, and s coming from ?
        // where are mgh 10/20 being used? Is that bandwidth ?
        // you said to leave walls as empty string and it'll still work
        // walls are x1, y1, x2, y2, wall type, x1, y1, etc
        const propData = {
            predictionID: "", 
            UID: uid,
            projectID: projId,
            planId: planId,
            f: "3600",
            h: parseInt(imageBinaryHeight/s),
            w: parseInt(imageBinaryWidth/s),
            n: "2.4",
            bw: bandwidth,
            txs: transmitterString,
            s: s,
            walls: wallsString || "0,0,0,0,4",
            type: propagationType
        }
        if (transmitterString == "") {
            props.setMessage({text: "Missing APs!  Please add APs to run prediction.", color: "#ff0000"});
            dispatch({type: "hideRunOptions"});
            return;
        }
        dispatch({type: "displayDesignSpinner"})
        dispatch({type: "hideRunOptions"})

        let prediction;
        try {
            prediction = (await getPropagation(propData)).data;
        } catch(err) {
            if (err) {    
                props.setMessage({text: "There was an error running this prediction. Please check your values or try again later.", color: "#ff0000"});
                dispatch({type: "hideDesignSpinner"})
                return;
            }
        }  

        const {heatmap, legend} = prediction;

        setDesignState({...designState, lastPrediction: heatmap, lastLegend: legend});

        //store results - json of propagation and legend
        
        dispatch({type: "displayPropagation"})
        dispatch({type: "hideDesignSpinner"})
    }

    const handleCancel = (e)=>{
        e.preventDefault();
        dispatch({type: "hideRunOptions"});
    }

    return (
        <>
        <div className="modal-container">
            <div className="floorplans-card">
                <div className="floorplans-header">
                    <span>Choose Prediction Type</span>
                </div>
                <div className="floorplan-list-card">
                    <a className="floorplan-list-summary blue-link" onClick={(e) => requestPropagation(e, "RSRP")} href="" type="button">
                        <p className="floorplan-list-name">RSRP</p>
                    </a>
                </div>
                <div className="floorplan-list-card">
                    <a className="floorplan-list-summary blue-link" onClick={(e) => requestPropagation(e, "RSSI")} href="" type="button">
                        <p className="floorplan-list-name">RSSI</p>
                    </a>
                </div>
                <div className="floorplan-list-card">
                    <a className="floorplan-list-summary blue-link" onClick={(e) => requestPropagation(e, "BestServer")} href="" type="button">
                        <p className="floorplan-list-name">Best Server</p>
                    </a>
                </div>

                <div onClick={handleCancel} className="design-modal-footer">
                    <a className="design-cancel-form" href="">
                        Cancel
                    </a>
                </div>
            </div>
        </div>
        
        </>
    )
}

export default RunOptions;
