import axios from "axios";

export const getWalls = async (wallData) => {
    try {
        const walls = await axios.post("/api/walls", wallData);
        if (walls) {
            return walls;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};

export const updateFloorplanWalls = async (user, projId, planId, walls) => {
    const floorplanToUpdate = {
        user, 
        projId,
        planId,
        walls
    }
    try {
        axios.put("/api/floorplans/walls", floorplanToUpdate);
        return;
    } catch (err) {
        throw err;
    }
}


