import axios from "axios";

export const getPropagation = async (propData) => {
    try {
        const propImages = await axios.post("/api/propagations", propData);
        if (propImages) {
            return propImages;
        } else {
            return false;
        }
    } catch (err) {
        throw err;
    }
};


