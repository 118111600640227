import React, {useEffect, useLayoutEffect, useContext, useState} from "react";
import { useHistory, useParams } from "react-router";
import {AuthUserContext} from "../../session/AuthUserContext";
import DesignContext from "../../store/DesignContext";
import { useDesignNavContext } from "../../store/DesignNavContext";
import "../../styles/design/apOptions.css";
import { updateFloorplanTransmitters } from "../../utils/apis";
import cancelIcon from "../../images/close.svg";


function APOptions(props) {

    const {uid}= useContext(AuthUserContext);

    const [state, dispatch] = useDesignNavContext();
    const [designState, setDesignState] = useContext(DesignContext);

    const {projId, planId, imageBinaryHeight, imageBinaryWidth, binaryTransmitters, transmitters} = designState;

    const [height, setHeight] = useState(251)
    const [top, setTop] = useState("20px")

    const [left, setLeft] = useState("20px")
    const [power, setPower] = useState(props.clickedIcon.power || 20)
    const [label, setLabel] = useState(props.clickedIcon.label || "")
    const [powerInput, setPowerInput] = useState(null)
    const [labelInput, setLabelInput] = useState("")
    const [apIdx, setAPIdx] = useState(props.clickedIcon.apIdx)
    const [activeState, setActiveState] = useState("options")


 
    useEffect( async () => {
        const parentContainerWidth = props.containerRef.current.getBoundingClientRect().width;
        const parentContainerHeight = props.containerRef.current.getBoundingClientRect().height;
        const x = parseFloat(props.clickedIcon.x)
        const y = parseFloat(props.clickedIcon.y)
        const xToDiv = parseFloat(props.clickedIcon.xToDiv)
        const yToDiv = parseFloat(props.clickedIcon.yToDiv)
        if (x && y) {
            if ( (xToDiv + 180) < parentContainerWidth) {
                setLeft(`${x}px`);
            } else {
                setLeft(`${x - 180}px`);
            }

            if ( (yToDiv + 315) < parentContainerHeight) {
                setTop(`${y}px`)
            } else {
                if (activeState === "options") {
                    setTop(`${y-251}px`)
                } else {
                    setTop(`${y-195}px`)
                }
                
            }
        } else {
            const left = parentContainerWidth/2 - 90;//90 is half width of card
            setLeft(`${left}px`);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect( async () => {
    })

    const handleDelete = (e) => {
        e.preventDefault();
  
        // make copy
        const binaryTransmittersCopy = [...binaryTransmitters]
        const transmittersCopy = [...transmitters]

        // delete from copy
        binaryTransmittersCopy.splice(apIdx, 1)
        transmittersCopy.splice(apIdx, 1)

        // save to state
        setDesignState({...designState, binaryTransmitters: binaryTransmittersCopy, transmitters: transmittersCopy})

        //save to firebase
        updateFloorplanTransmitters(uid, projId, planId, binaryTransmittersCopy);

        dispatch({type: "hideAPOptions"})
    }

    const handleEditPower = (e) => {
        e.preventDefault();
        setHeight(195);
        setActiveState("power");
    }

    const handleEditLabel = (e) => {
        e.preventDefault();
        setHeight(195);
        setActiveState("label");
    }

    const handleChange = (event) => {
        const {value} = event.target;

        if (activeState === "power") {
            setPower(value);
        } else if (activeState === "label") {
            setLabel(value);
        } else {
            return;
        }
    };
    
    
    const handleSubmit = async(event) => {
        event.preventDefault();

        // make copy
        let binaryTransmittersCopy = [...binaryTransmitters]
        let transmittersCopy = [...transmitters]

        if (activeState === "power") {
            //update copy
            binaryTransmittersCopy[apIdx][1] = power;

        } else if (activeState === "label") {

            //update copy
            binaryTransmittersCopy[apIdx][4] = label;
            transmittersCopy[apIdx]["label"] = label;
        } else {
            return;
        }

        // save to state
        setDesignState({...designState, binaryTransmitters: binaryTransmittersCopy, transmitters: transmittersCopy})

        //save to firebase
        updateFloorplanTransmitters(uid, projId, planId, binaryTransmittersCopy);

        dispatch({type: "hideAPOptions"})
    }

    const handleCancel = (e)=>{
        e.preventDefault();
        dispatch({type: "hideAPOptions"});
      }

    return (
        <div className="icon-options-container">
            <div className="icon-options-card" style={{top, left, height}}>
                <div onClick={handleCancel} className="design-cancel-icon-div">
                    <img src={cancelIcon} alt="close-icon"/>
                </div>
                {activeState === "options" && (
                <>
                    <div >
                        <span className="icon-options-header">{label || "EditAP"}</span>
                        <span className="icon-options-subheader">{power} dBm</span>
                    </div>

                    <div onClick = {(e) => handleDelete(e)} className="icon-options-list-card">
                        <a className="icon-options-list-summary blue-link" href="" type="button">
                            <p className="icon-options-list-name">Delete AP</p>
                        </a>
                    </div>
                    <div onClick = {(e) => handleEditPower(e)} className="icon-options-list-card">
                        <a className="icon-options-list-summary blue-link" href="" type="button">
                            <p className="icon-options-list-name">Update AP Power</p>
                        </a>
                    </div>
                    <div onClick = {(e) => handleEditLabel(e)} className="icon-options-list-card">
                        <a className="icon-options-list-summary blue-link" href="" type="button">
                            <p className="icon-options-list-name">Update AP Name</p>
                        </a>
                    </div>
                </>
                )}

                {activeState === "power" && (
                <>
                <div >
                    <span className="icon-options-header">Edit AP Power</span>
                    <span className="icon-options-subheader">{power || 23} dBm</span>
                </div>

                <form onSubmit={handleSubmit}>
                    <input
                        className="icon-input"
                        type="text"
                        name="power"
                        placeholder="enter 0-27"
                        onChange={handleChange}
                    />
                    <button type="submit" className="icon-submit">
                        Save
                    </button>
                </form>
                </>
                )}

                {activeState === "label" && (
                <>
                <div >
                    <span className="icon-options-header">Edit AP Name</span>
                    <span className="icon-options-subheader">{label || ""}</span>
                </div>

                <form onSubmit={handleSubmit}>
                    <input
                        className="icon-input"
                        type="text"
                        name="floorplanName"
                        placeholder="enter name"
                        onChange={handleChange}
                    />
                    <button type="submit" className="icon-submit">
                        Save
                    </button>
                </form>
                </>
                )}
            </div>
        </div>
    )
}

export default APOptions;
